<template>
  <div>
    <div
        v-for="dateStr in Object.keys(history)"
        :key="'history_'+dateStr"
    >

      <!-- Event-->
      <timeline v-if="history[dateStr].event.length">
        <time-line-item
            v-for="(event, eventIndex) in history[dateStr].event"
            :key="'history_'+dateStr+'_event_'+eventIndex"
            :title="capitalize($tc('event', history[dateStr].event.length))"
            icon="calendar-day"
        >
          <template #time>
            <span> {{ capitalize(displayDate(event.startDate, true)) }}</span>
          </template>
          <event-card
              :event="event"
              :is-history="true"

              @edit="$emit('activityFunctionCalled', {entityType: 'event', entity: event, functionCalled:'edit'})"
              @delete="$emit('activityFunctionCalled', {entityType: 'event', entity: event, functionCalled:'delete'})"
          />
        </time-line-item>
      </timeline>

      <!-- Reminder-->
      <timeline v-if="history[dateStr].reminder.length">
        <time-line-item
            v-for="(reminder, reminderIndex) in history[dateStr].reminder"
            :key="'history_'+dateStr+'_reminder_'+reminderIndex"
            :title="capitalize($tc('reminder', history[dateStr].reminder.length))"
            icon="calendar-day"
        >
          <template #time>
            <span> {{ capitalize(displayDate(dateStr)) }}</span>
          </template>
          <reminder-card
              :reminder="reminder"
              :is-history="true"

              @edit="$emit('activityFunctionCalled', {entityType: 'reminder', entity: reminder, functionCalled:'edit'})"
              @delete="$emit('activityFunctionCalled', {entityType: 'reminder', entity: reminder, functionCalled:'delete'})"
          />
        </time-line-item>
      </timeline>

      <!-- Offer-->
      <timeline v-if="history[dateStr].offer.length">
        <time-line-item
            v-for="(offer, offerIndex) in history[dateStr].offer"
            :key="'history_'+dateStr+'_offer_'+offerIndex"
            :title="capitalize($tc('offer', history[dateStr].offer.length))"
            icon="file-invoice"
        >
          <template #time>
            <span> {{ capitalize(displayDate(dateStr)) }}</span>
          </template>

          <offer-card
              :offer="offer"
              :is-history="true"

              @view="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'view'})"
              @validate="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'validate'})"
              @decline="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'decline'})"
              @transformToOutgoingInvoice="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'transformToOutgoingInvoice'})"
              @edit="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'edit'})"
              @archivate="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'archivate'})"
              @unArchivate="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'unArchivate'})"
              @duplicate="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'duplicate'})"
              @delete="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'delete'})"
          />
        </time-line-item>
      </timeline>

      <!-- Validated offer-->
      <timeline v-if="history[dateStr].validatedOffer.length">
        <time-line-item
            v-for="(validatedOffer, validatedOfferIndex) in history[dateStr].validatedOffer"
            :key="'history_'+dateStr+'_validatedOffer_'+validatedOfferIndex"
            :title="capitalize($tc('order', history[dateStr].validatedOffer.length))"
            icon="file-signature"
        >
          <template #time>
            <span> {{ capitalize(displayDate(dateStr)) }}</span>
          </template>

          <validated-offer-card
              :offer="validatedOffer"
              :is-history="true"

              @view="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'view'})"
              @validate="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'validate'})"
              @decline="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'decline'})"
              @transformToOutgoingInvoice="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'transformToOutgoingInvoice'})"
              @edit="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'edit'})"
              @archivate="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'archivate'})"
              @unArchivate="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'unArchivate'})"
              @duplicate="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'duplicate'})"
              @delete="$emit('activityFunctionCalled', {entityType: 'offer', entity: offer, functionCalled:'delete'})"
          />
        </time-line-item>
      </timeline>

      <!-- Outgoing invoice -->
      <timeline v-if="history[dateStr].outgoingInvoice.length">
        <time-line-item
            v-for="(outgoingInvoice, outgoingInvoiceIndex) in history[dateStr].outgoingInvoice"
            :key="'history_'+dateStr+'_outgoingInvoice_'+outgoingInvoiceIndex"
            :title="capitalize($tc('invoice', history[dateStr].outgoingInvoice.length))"
            icon="file-invoice-dollar"
        >
          <template #time>
            <span> {{ capitalize(displayDate(dateStr)) }}</span>
          </template>

          <outgoing-invoice-card
              :outgoing-invoice="outgoingInvoice"
              :is-history="true"

              @view="$emit('activityFunctionCalled', {entityType: 'outgoingInvoice', entity: outgoingInvoice, functionCalled:'view'})"
              @validate="$emit('activityFunctionCalled', {entityType: 'outgoingInvoice', entity: outgoingInvoice, functionCalled:'validate'})"
              @edit="$emit('activityFunctionCalled', {entityType: 'outgoingInvoice', entity: outgoingInvoice, functionCalled:'edit'})"
              @duplicate="$emit('activityFunctionCalled', {entityType: 'outgoingInvoice', entity: outgoingInvoice, functionCalled:'duplicate'})"
              @delete="$emit('activityFunctionCalled', {entityType: 'outgoingInvoice', entity: outgoingInvoice, functionCalled:'delete'})"
          />
        </time-line-item>
      </timeline>

      <!-- Incoming invoice -->
      <timeline v-if="history[dateStr].incomingInvoice.length">
        <time-line-item
            v-for="(incomingInvoice, incomingInvoiceIndex) in history[dateStr].incomingInvoice"
            :key="'history_'+dateStr+'_incomingInvoice_'+incomingInvoiceIndex"
            :title="capitalize($tc('expense', history[dateStr].incomingInvoice.length))"
            icon="credit-card"
        >
          <template #time>
            <span> {{ capitalize(displayDate(dateStr)) }}</span>
          </template>

          <incoming-invoice-card
              :incoming-invoice="incomingInvoice"
              :is-history="true"

              @view="$emit('activityFunctionCalled', {entityType: 'incomingInvoice', entity: incomingInvoice, functionCalled:'view'})"
              @edit="$emit('activityFunctionCalled', {entityType: 'incomingInvoice', entity: incomingInvoice, functionCalled:'edit'})"
              @delete="$emit('activityFunctionCalled', {entityType: 'incomingInvoice', entity: incomingInvoice, functionCalled:'delete'})"
          />
        </time-line-item>
      </timeline>

      <!-- Note -->
      <timeline v-if="history[dateStr].note.length">
        <time-line-item
            v-for="(note, noteIndex) in history[dateStr].note"
            :key="'history_'+dateStr+'_note_'+noteIndex"
            :title="capitalize($tc('note', history[dateStr].note.length))"
            icon="credit-card"
        >
          <template #time>
            <span> {{ capitalize(displayDate(note._createdAt, true)) }}</span>
          </template>

          <work-flo-note-history-card
              :note="note"
              :is-history="true"

              @edit="$emit('activityFunctionCalled', {entityType: 'workFloNote', entity: note, functionCalled:'edit'})"
              @delete="$emit('activityFunctionCalled', {entityType: 'workFloNote', entity: note, functionCalled:'delete'})"
          />
        </time-line-item>
      </timeline>

      <!-- Email-->
      <timeline v-if="history[dateStr].email.length">
        <time-line-item
            v-for="(email, emailIndex) in history[dateStr].email"
            :key="'history_'+dateStr+'_email_'+emailIndex"
            :title="capitalize($tc('email', history[dateStr].email.length))"
            icon="envelope"
        >
          <template #time>
            <span> {{ capitalize(displayDate(email.receivedDateTime, true)) }}</span>
          </template>
          <email-card
              :email="email"
              :is-history="true"
          />
        </time-line-item>
      </timeline>


    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import Timeline                 from '../../../components/timeline/Timeline.vue'
import OutgoingInvoiceCard      from '../../../components/card/OutgoingInvoiceCard.vue'
import ValidatedOfferCard       from '../../../components/card/ValidatedOfferCard.vue'
import OfferCard                from '../../../components/card/OfferCard.vue'
import WorkFloNoteHistoryCard   from '../../../components/card/WorkFloNoteHistoryCard.vue'
import IncomingInvoiceCard      from '../../../components/card/IncomingInvoiceCard.vue'
import TimeLineItem             from '../../../components/timeline/TimeLineItem.vue'
import EventCard                from '../../../components/card/EventCard.vue'
import ReminderCard             from '../../../components/card/ReminderCard.vue'
import { capitalize }           from '../../../utils/filter'
import { clone }                from '../../../utils/utils'
import i18n                     from '../../../libs/i18n'
import moment                   from 'moment'
import EmailCard                from '../../../components/card/EmailCard.vue'

export default {
  components: {
    EmailCard,
    ReminderCard,
    EventCard,
    TimeLineItem,
    IncomingInvoiceCard,
    WorkFloNoteHistoryCard,
    OfferCard,
    ValidatedOfferCard,
    OutgoingInvoiceCard,
    Timeline
  },
  props: {
    history: {
      type: [Object, Array],
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const displayDate = (dateStr, displayTime = false) => {
      let now = moment().hour(0).minute(0).seconds(0)
      let tomorrow = moment(clone(now)).add(1, 'days')

      if (now.format('YYYY-MM-DD') == dateStr) {
        return capitalize(i18n.t('today'))
      } else if (tomorrow.format('YYYY-MM-DD') == dateStr) {
        return capitalize(i18n.t('tomorrow'))
      } else {
        if (displayTime) {
          return capitalize(moment(dateStr).format('LLL'))
        } else {
          return capitalize(moment(dateStr).format('LL'))
        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
      displayDate,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>