<template>
  <div>

    <!--    <pre>{{ localHistory }}</pre>-->
    <separator
        :title="$t('cominSoon')"
        icon="calendar-alt"
        variant="primary"
        class="my-5"
    />

    <timeline>
      <time-line-item
          v-if="'workFloTasks' in localComingSoonHistory && localComingSoonHistory.workFloTasks.length"
          icon="check"
      >
        <work-flo-task-card
            v-for="(comingSoonWorkFloTask, index) in localComingSoonHistory.workFloTasks"
            :key="'activity_tab_comingSoon_workFloTasks_'+index"
            :work-flo-task="comingSoonWorkFloTask"
        />
      </time-line-item>
    </timeline>

    <timeline>
      <time-line-item
          v-if="'events' in localComingSoonHistory && localComingSoonHistory.events.length"
          icon="calendar-alt"
      >
        <work-flo-event-card
            v-for="(comingSoonWorkFloEvent, index) in localComingSoonHistory.events"
            :key="'activity_tab_comingSoon_events_'+index"
            :work-flo-event="comingSoonWorkFloEvent"
        />
      </time-line-item>
    </timeline>

    <timeline>
      <time-line-item
          v-if="'workFloCalls' in localComingSoonHistory && localComingSoonHistory.workFloCalls.length"
          icon="phone"
      >
        <work-flo-call-card
            v-for="(comingSoonWorkFloCall, index) in localComingSoonHistory.workFloCalls"
            :key="'activity_tab_comingSoon_workFloCall_'+index"
            :work-flo-call="comingSoonWorkFloCall"
        />
      </time-line-item>
    </timeline>

    <timeline class="mb-4">
      <time-line-item
          v-if="'interventions' in localComingSoonHistory && localComingSoonHistory.interventions.length"
          icon="road"
      >
        <work-flo-intervention-card
            v-for="(comingSoonWorkFloInterventions, index) in localComingSoonHistory.interventions"
            :key="'activity_tab_comingSoon_workFloInterventionl_'+index"
            :work-flo-intervention="comingSoonWorkFloInterventions"
        />
      </time-line-item>
    </timeline>


    <separator
        :title="$t('history')"
        icon="history"
        class="my-5"
    />

    <timeline>
      <time-line-item
          v-for="(history, index) in localHistory"
          :key="'activity_tab_history_'+index"
          :icon="getIcon(history)"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <div>
            <h6 class="mb-0">
              <span v-if="['self', 'workFloNote', 'workFloCall'].includes(history.type)">{{ $t(history.title) }}</span>
              <span v-else-if="history.type == 'workFloTask'">
                <span v-if="history.isCreation">{{ $t('TaskCreation:') }} {{ history.title }}</span>
                <span v-else>{{ $t('TaskModification:') }} {{ history.title }}</span>
              </span>
              <span v-else-if="history.type == 'event'">
                <span v-if="history.isCreation">{{ $t('NewEvent:') }} {{ history.title }}</span>
                <span v-else>{{ $t('EventModification:') }} {{ history.title }}</span>
              </span>
              <span v-else-if="history.type == 'intervention'">
                <span v-if="history.isCreation">{{ $t('NewIntervention:') }} {{ history.title }}</span>
                <span v-else>{{ $t('InterventionModification:') }} {{ history.title }}</span>
              </span>
              <span v-else-if="history.type == 'email'">
                <span>{{ history.title }}</span>
              </span>

            </h6>
            <small class="text-muted">
              <i>{{ isObject(history.by)?history.by._display:'-' }}</i>
            </small>
          </div>
          <small class="text-muted">{{ history.date|fromNow(true)|capitalize }}</small>
        </div>

        <div v-if="history.isCreation">
          <div v-if="history.type=='self'">
            <dl
                v-for="(value, field) in history.new"
                :key="'activity_tab_history_creation_log_'+field"
                class="row mb-0"
            >
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc(field)) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="value == null">-</span>
                <span v-else-if="['source', 'state'].includes(field)">{{ capitalize($t(value)) }}</span>
                <span v-else-if="['false', 'true'].includes(value)">{{ $t(value) }}</span>
                <span v-else>{{ value }}</span>
              </dd>
            </dl>
          </div>

          <div v-else-if="history.type == 'workFloTask'">
            <dl class="row mb-0">
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('title')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.title == null">-</span>
                <span v-else>{{ history.new.title }}</span>
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('date')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.date == null">-</span>
                <span v-else>{{ history.new.date|moment('LL') }}</span>
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('linkedContact')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.linkedContact == null">-</span>
                <span v-else>{{ history.new.linkedContact }}</span>
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('performed')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.performed == null">-</span>
                <span v-else>{{ history.new.performed }}</span>
              </dd>
            </dl>

          </div>

          <div v-else-if="history.type == 'workFloNote'">
            <dl class="row mb-0">
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('note')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.note == null">-</span>
                <span
                    v-else
                    v-html="history.new.note"
                />
              </dd>
            </dl>
          </div>

          <div v-if="history.type=='workFloCall'">
            <dl
                v-for="(value, field) in history.new"
                :key="'activity_tab_history_creation_workFloCall_log_'+field"
                class="row mb-0"
            >
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc(field)) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="value == null">-</span>
                <span
                    v-else-if="field=='note'"
                    v-html="value"
                />
                <span v-else>{{ value }}</span>
              </dd>
            </dl>
          </div>

          <div v-if="history.type=='event' || history.type=='intervention'">
            <dl class="row mb-0">
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('title')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.title == null">-</span>
                <span v-else>{{ history.new.title }}</span>
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('startDate')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.startDate == null">-</span>
                <span v-else>{{ history.new.startDate }}</span>
              </dd>
            </dl>
            <dl class="row mb-0">
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('endDate')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.endDate == null">-</span>
                <span v-else>{{ history.new.endDate }}</span>
              </dd>
            </dl>

            <dl
                v-if="history.type=='intervention'"
                class="row mb-0"
            >
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('type')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.type == null">-</span>
                <span v-else>{{ history.new.type }}</span>
              </dd>
            </dl>
            <dl
                v-if="history.type=='intervention'"
                class="row mb-0"
            >
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('place')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.place == null">-</span>
                <span v-else>{{ history.new.place }}</span>
              </dd>
            </dl>
            <dl
                v-if="history.type=='intervention'"
                class="row mb-0"
            >
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('startPlace')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.startPlace == null">-</span>
                <span v-else>{{ history.new.startPlace }}</span>
              </dd>
            </dl>
            <dl
                v-if="history.type=='intervention'"
                class="row mb-0"
            >
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('endPlace')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.endPlace == null">-</span>
                <span v-else>{{ history.new.endPlace }}</span>
              </dd>
            </dl>
            <dl
                v-if="history.type=='intervention'"
                class="row mb-0"
            >
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('distance')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.distance == null">-</span>
                <span v-else>{{ history.new.distance }}</span>
              </dd>
            </dl>
            <dl
                v-if="history.type=='intervention'"
                class="row mb-0"
            >
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('mileageAllowanceCost')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new[$t('mileageAllowanceCost')] == null">-</span>
                <span v-else>{{ history.new[$t('mileageAllowanceCost')] }}</span>
              </dd>
            </dl>


          </div>

          <div v-if="history.type=='email'">
            <span v-html="history.new.bodyPreview" />
          </div>

        </div>

        <div v-else>
          <div v-if="history.type == 'workFloNote'">
            <dl class="row mb-0">
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc('note')) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="history.new.note == null">-</span>
                <span
                    v-else
                    v-html="history.new.note"
                />
              </dd>
            </dl>
          </div>
          <div v-else>
            <dl
                v-for="(value, field) in history.old"
                :key="'activity_tab_history_modification_log_'+field"
                class="row mb-0"
            >
              <dt class="col-sm-3 text-right">
                <b>{{ capitalize($tc(field)) }}</b>
              </dt>
              <dd class="col-sm-9">
                <span v-if="['source', 'state'].includes(field)">{{ capitalize($t(value)) }}</span>
                <span v-else-if="['false', 'true'].includes(value)">{{ $t(value) }}</span>
                <span
                    v-else-if="history.type == 'workFloCall' && field=='note'"
                    v-html="value"
                />
                <span v-else-if="value == null">-</span>
                <span v-else>{{ value }}
                  <icon
                      icon="caret-right"
                      class="mx-50"
                  />
                             {{ history.new[field] == null ? '-' : history.new[field] }}
                </span>
              </dd>
            </dl>
          </div>

        </div>


      </time-line-item>

    </timeline>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../utils/filter'
import { isObject } from '../../../utils/utils'

import moment from 'moment'
import store from '../../../store'
import i18n from '../../../libs/i18n'

import Timeline from '../../../components/timeline/Timeline.vue'
import TimeLineItem from '../../../components/timeline/TimeLineItem.vue'
import Separator from '../../../components/separator/Separator.vue'
import WorkFloTaskCard from '../../../components/card/WorkFloTaskCard.vue'
import WorkFloEventCard from '../../../components/card/WorkFloEventCard.vue'
import WorkFloCallCard from '../../../components/card/WorkFloCallCard.vue'
import WorkFloInterventionCard from '../../../components/card/WorkFloInterventionCard.vue'

export default {
  components: {
    Timeline,
    TimeLineItem,
    Separator,
    WorkFloTaskCard,
    WorkFloEventCard,
    WorkFloCallCard,
    WorkFloInterventionCard
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    const localComingSoonHistory = computed(() => {
      if ('_history' in props.workFlo) {
        let localhistoryTmp = JSON.parse(JSON.stringify(props.workFlo._history.comingSoon))

        localhistoryTmp.workFloCalls.forEach((c,i)=> {
          localhistoryTmp.workFloCalls[i].duration = moment(c.duration).utc().format('LTS')
        })

        return localhistoryTmp
      } else {
        return []
      }
    })

    const localHistory = computed(() => {
      if ('_history' in props.workFlo) {
        let localhistoryTmp = JSON.parse(JSON.stringify(props.workFlo._history.history))
        localhistoryTmp.forEach((history, indexHistory) => {

          for (const [field, value] of Object.entries(history['new'])) {
            if (field == 'assignment') {
              localhistoryTmp[indexHistory]['new'][field] = getEmployee(value.id)
            } else if (['mainContact', 'linkedContact', 'contact'].includes(field) && value != null) {
              localhistoryTmp[indexHistory]['new'][field] = getUser(value.id)
            } else if (field == 'company') {
              localhistoryTmp[indexHistory]['new'][field] = getCompany(value.id)
            } else if (field == 'status') {
              localhistoryTmp[indexHistory]['new'][field] = getStatus(value.id)
            } else if (['prescriberIndividual', 'prescriberCompany', 'businessIntroducerIndividual', 'businessIntroducerCompany'].includes(field)) {
              localhistoryTmp[indexHistory]['new'][i18n.t(field)] = value
              delete localhistoryTmp[indexHistory]['new'][field]
            } else if (value == true || value == false) {
              localhistoryTmp[indexHistory]['new'][field] = value == true ? i18n.t('yes') : i18n.t('no')
            } else if (history.type == 'workFloCall') {
              if (field == 'date') {
                localhistoryTmp[indexHistory]['new'][field] = moment(value).format('LLL')
              } else if (field == 'duration') {
                localhistoryTmp[indexHistory]['new'][field] = moment(value).format('LTS')
              } else if (field == 'resultType') {
                localhistoryTmp[indexHistory]['new'][field] = i18n.t(value)
              }
            } else if (history.type == 'event' || history.type == 'intervention') {
              if (field == 'startDate' || field == 'endDate') {
                localhistoryTmp[indexHistory]['new'][field] = moment(value).format('LLL')
              } else if (['place', 'startPlace', 'endPlace'].includes(field) && value != null) {
                localhistoryTmp[indexHistory]['new'][field] = value._display
              } else if (field == 'type') {
                localhistoryTmp[indexHistory]['new'][field] = i18n.t(value)
              } else if (field == 'mileageAllowanceCost') {
                localhistoryTmp[indexHistory]['new'][i18n.t(field)] = currency(value)
                delete localhistoryTmp[indexHistory]['new'][field]
              }
            }
          }

          for (const [field, value] of Object.entries(history['old'])) {
            if (field == 'assignment') {
              localhistoryTmp[indexHistory]['old'][field] = getEmployee(value.id)
            } else if (['mainContact', 'linkedContact', 'contact'].includes(field) && value != null) {
              localhistoryTmp[indexHistory]['old'][field] = getUser(value.id)
            } else if (field == 'company') {
              localhistoryTmp[indexHistory]['old'][field] = getCompany(value.id)
            } else if (field == 'status') {
              localhistoryTmp[indexHistory]['old'][field] = getStatus(value.id)
            } else if (['prescriberIndividual', 'prescriberCompany', 'businessIntroducerIndividual', 'businessIntroducerCompany'].includes(field)) {
              localhistoryTmp[indexHistory]['old'][i18n.t(field)] = value
              delete localhistoryTmp[indexHistory]['old'][field]
            } else if (value == true || value == false) {
              localhistoryTmp[indexHistory]['old'][field] = value == true ? i18n.t('yes') : i18n.t('no')
            } else if (history.type == 'workFloCall') {
              if (field == 'date') {
                localhistoryTmp[indexHistory]['old'][field] = moment(value).format('LLL')
              } else if (field == 'duration') {
                localhistoryTmp[indexHistory]['old'][field] = moment(value).format('LTS')
              } else if (field == 'resultType') {
                localhistoryTmp[indexHistory]['old'][field] = i18n.t(value)
              }
            } else if (history.type == 'event' || history.type == 'intervention') {
              if (field == 'startDate' || field == 'endDate') {
                localhistoryTmp[indexHistory]['old'][field] = moment(value).format('LLL')
              } else if (['place', 'startPlace', 'endPlace'].includes(field) && value != null) {
                localhistoryTmp[indexHistory]['old'][field] = value._display
              } else if (field == 'type') {
                localhistoryTmp[indexHistory]['old'][field] = i18n.t(value)
              } else if (field == 'mileageAllowanceCost') {
                localhistoryTmp[indexHistory]['old'][i18n.t(field)] = currency(value)
                delete localhistoryTmp[indexHistory]['old'][field]
              }
            }
          }

        })

        // console.log(localhistoryTmp)

        return localhistoryTmp
      } else {
        return []
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getIcon = (history) => {
      if (history.type == 'self') {
        return history.isCreation ? 'plus' : 'edit'
      } else if (history.type == 'workFloTask') {
        if (history.taskType == 'task') {
          return 'check'
        } else if (history.taskType == 'call') {
          return 'phone'
        } else if (history.taskType == 'appointment') {
          return 'calendar-alt'
        } else if (history.taskType == 'email') {
          return 'envelope'
        } else { // milestone
          return 'map-marked-alt'
        }
      } else if (history.type == 'workFloNote') {
        return 'comment-dots'
      } else if (history.type == 'workFloCall') {
        return 'phone'
      } else if (history.type == 'event') {
        return 'calendar-alt'
      } else if (history.type == 'intervention') {
        return 'road'
      } else if (history.type == 'email') {
        return 'envelope'
      }
    }

    const getEmployee = (employeeId) => {
      let employee = store.getters['user2/getAutocompleteEmployees'].find(e => e.id == employeeId)

      if (employee !== undefined) {
        return employee._display
      } else {
        return null
      }
    }

    const getUser = (userId) => {
      let user = store.getters['user2/getAutocompleteUsers'].find(u => u.id == userId)

      if (user !== undefined) {
        return user._display
      } else {
        return null
      }
    }

    const getCompany = (companyId) => {
      let company = store.getters['company/getAutocompleteCompanies'].find(c => c.id == companyId)

      if (company !== undefined) {
        return company._display
      } else {
        return null
      }
    }

    const getStatus = (statusId) => {
      let status = store.getters['workFloStatus/getAutocompleteWorkFloStatuss'].find(s => s.id == statusId)

      if (status !== undefined) {
        return status.name
      } else {
        return null
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components
      capitalize,

      // Data

      // Computed
      workFloLocal,
      localComingSoonHistory,
      localHistory,

      // Methods
      getIcon,
      getEmployee,
      getUser,
      getCompany,
      getStatus,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { isObject },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
hr.hr-text {
  position: relative;
  //border: none;
  //height: 1px;
  //background: #999;
}

hr.hr-text::before {
  content: attr(data-content);
  display: inline-block;
  background: #F8F8F8FF;
  font-weight: bold;
  font-size: 0.85rem;
  color: #999;
  border-radius: 30rem;
  padding: 0.2rem 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>