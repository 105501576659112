<template>
  <b-toast
      :visible="isOpenLocal"
      :no-auto-hide="true"
      :solid="true"
      toaster="b-toaster-bottom-right"
  >
    <template #toast-title>
      <slot name="title"/>
    </template>

    <slot name="content"/>

    <slot name="bottom">
      <send-button
          size="sm"
          class="mr-1"

          @click="$emit('send')"
      />

      <cancel-button
          size="sm"

          @click="isOpenLocal=false"
      />
    </slot>

  </b-toast>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { generateUuid } from '@azure/core-http'

import SendButton from '../button/Send2.vue'
import CancelButton from '../button/Cancel.vue'

export default {
  components: {
    SendButton,
    CancelButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isOpenLocal = computed({
      get () {
        return props.isOpen
      },
      set (val) {
        emit('update:isOpen', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      isOpenLocal,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.toast-header {
  background-color: #82868b;
  color: white;
}

</style>