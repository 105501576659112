<template>
  <div class="mb-2">
<!--    <textarea-field-->
<!--        name="note"-->
<!--        :model.sync="workFloNoteLocal.note"-->
<!--        :is-required="true"-->
<!--    />-->

<!--    <c-k-editor-->
<!--        :text.sync="workFloNoteLocal.note"-->
<!--    />-->

    <input-textarea
        :text.sync="workFloNoteLocal.note"
      />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import TextareaField from '../input/Textarea.vue'
import InputTextarea from '../input/InputTextarea.vue'

export default {
  components: {
    InputTextarea,
    TextareaField,
  },
  props: {
    workFloNote: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloNoteLocal = computed({
      get () {
        return props.workFloNote
      },
      set (val) {
        emit('update:workFloNote', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      workFloNoteLocal,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>