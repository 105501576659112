<template>
  <b-card
      no-body
      class="overflow-hidden cursor-default p-50"

      @mouseover="isCardHovered=true"
      @mouseleave="isCardHovered=false"
      @click="$emit('click')"
  >


    <div class="d-flex justify-content-between px-50">
      <div class="text-nowrap text-truncate my-auto w-100 mr-1">
        <b-avatar
            v-if="workFloNote._createdBy.avatar"
            class="pull-up"
            :src="workFloNote._createdBy.avatar.url"
            variant="light-primary"
            size="sm"
        />
        <b-avatar
            v-else
            class="pull-up"
            :text="getInitials(workFloNote._createdBy._display)"
            variant="light-primary"
            size="sm"
        />

        <span class="ml-1">
          <b>{{ workFloNote._createdBy._display }}</b>
        </span>
      </div>

      <div class="text-nowrap text-secondary my-auto">
        <small>
          <i>{{ capitalize(getFromNow(workFloNote._createdAt)) }}</i>
        </small>
      </div>


      <Transition name="slide">
        <div v-if="isCardHovered"
             class="text-nowrap my-auto ml-2">
          <icon
              icon="edit"
              class="mr-1 cursor-pointer"
              :class="{'text-warning': isEditHovered}"
              :disabled="isEditDisabled||workFloNote._createdBy.id != getUserData().id"

              @mouseover.native="isEditHovered=true"
              @mouseleave.native="isEditHovered=false"
              @click.native="$emit('editClick')"
          />

          <icon
              icon="trash-alt"
              class="mr-1 cursor-pointer"
              :class="{'text-danger': isTrashHovered}"
              :disabled="isTrashDisabled||workFloNote._createdBy.id != getUserData().id"

              @mouseover.native="isTrashHovered=true"
              @mouseleave.native="isTrashHovered=false"
              @click.native="$emit('trashClick')"
          />
        </div>
      </Transition>

    </div>

    <div v-html="workFloNote.note"
         class="px-1 pt-1 workFloNoteTextArea"/>

  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'
import { getUserData } from '../../utils/utils'

import moment from 'moment'
import i18n from '../../libs/i18n'

export default {
  components: {},
  props: {
    workFloNote: {
      type: Object,
      default: () => {}
    },
    isEditDisabled: {
      type: Boolean,
      default: false
    },
    isTrashDisabled: {
      type: Boolean,
      default: false
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isCardHovered = ref(false)
    const isEditHovered = ref(false)
    const isTrashHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    const getInitials = (display) => {
      let names = display.split(' '),
          initials = names[0].substring(0, 1).toUpperCase()

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    }

    const getFromNow = (date) => {
      return moment(date).fromNow()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      getUserData,

      // Data
      isCardHovered,
      isEditHovered,
      isTrashHovered,

      // Computed

      // Methods
      getInitials,
      getFromNow,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.slide-leave-active,
.slide-enter-active {
  transition: .3s;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-to {
  transform: translateX(200%);
}

.workFloNoteTextArea {
  p {
    margin-bottom: 0px;
  }
}
</style>