<template>
  <div>
    <b-card
        no-body
        class="overflow-hidden p-1"
        @mouseover="isHovered=true"
        @mouseleave="isHovered=false"
        @click="$emit('click')"
    >
      <div class="d-flex">
        <div class="w-100">
          <!--          Line 1-->
          <div class="d-flex justify-content-between">
            <div
                class="d-flex"
                :style="'width: calc(98% - '+iconsWidth+')'"
            >
              <div v-if="isSelectable">
                <b-form-checkbox
                    v-model="check"
                />
              </div>
              <div>
                <icon
                    icon="envelope"
                    class="mx-50"
                />
              </div>
              <!--              <div class="text-truncate" style="max-width: 14vw">-->
              <div class="text-truncate">
                <b>{{ email.subject }}</b>
              </div>
<!--              <div-->
<!--                  v-if="email.receivedDateTime != null"-->
<!--                  class="ml-50"-->
<!--              >-->
<!--                <b-badge :variant="daysFromNow.color">-->
<!--                  {{ daysFromNow.text }}-->
<!--                </b-badge>-->
<!--              </div>-->
            </div>

            <div
                class="my-auto d-flex justify-content-end"
                :style="'width: '+iconsWidth+ ';'"
            >
              <icon
                  v-if="email.hasAttachments"
                  icon="paperclip"
                  class="mx-50"
              />
            </div>

          </div>

          <!--          Line 2-->
          <div class="d-flex mt-1">
            <div
                class="d-flex"
            >
              <b-avatar
                  v-if="email.fromRecipient.emailAddress.address"
                  v-b-tooltip.hover
                  class="pull-up"
                  :text="initialsFromEmail(email.fromRecipient.emailAddress.address)"
                  :title="email.fromRecipient.emailAddress.address"
                  variant="light-primary"
                  size="sm"
              />

              <div class="ml-50 text-truncate">
                {{ email.fromRecipient.emailAddress.address }}
              </div>
            </div>

            <div class="mx-4">
              <icon icon="angle-right" />
            </div>

            <div>
              <div
                  v-if="email.toRecipients.length==1"
                  class="d-flex"
              >
                <b-avatar
                    v-if="email.toRecipients[0].emailAddress.address"
                    v-b-tooltip.hover
                    class="pull-up"
                    :text="initials(email.toRecipients[0].emailAddress.address)"
                    :title="email.toRecipients[0].emailAddress.address"
                    variant="light-primary"
                    size="sm"
                />

                <div class="ml-50 text-truncate">
                  {{ email.toRecipients[0].emailAddress.address }}
                </div>
              </div>
              <div v-else>
                <b-avatar
                    v-for="teRecipient in email.toRecipients"
                    v-b-tooltip.hover
                    class="pull-up"
                    :text="initialsFromEmail(teRecipient.emailAddress.address)"
                    :title="teRecipient.emailAddress.address"
                    variant="light-primary"
                    size="sm"
                />
              </div>

            </div>
          </div>

          <!--          Line 3-->
          <div
              class="d-flex mt-1"
          >
            <v-clamp
                autoresize
                :max-lines="3">
              {{ escapeHtml(email.bodyPreview) }}
              <template #after="{ toggle, clamped }">
                <span
                    v-if="clamped == true"
                    @click="toggle"
                    class="ml-50 text-primary cursor-pointer">
                  <b><i>{{ capitalize($t('viewAll')) }}</i></b>
                </span>
              </template>
            </v-clamp>
          </div>
        </div>

<!--        <Transition name="slide">-->
<!--          <div-->
<!--              v-if="isHovered && (isEditable || isDeletable)"-->
<!--              class="my-auto d-flex"-->
<!--          >-->
<!--            <edit-button-->
<!--                @click.native.stop="$emit('edit')"-->
<!--                :withIcon="true"-->
<!--                :withText="false"-->
<!--                :withBorder="false"-->
<!--                :disabled="!isEditable"-->
<!--                size="sm"-->
<!--            />-->

<!--            <remove-button-->
<!--                @click.native.stop="$emit('delete')"-->
<!--                :withIcon="true"-->
<!--                :withText="false"-->
<!--                :withBorder="false"-->
<!--                :is-disabled="!isDeletable"-->
<!--                size="sm"-->
<!--            />-->
<!--          </div>-->
<!--        </Transition>-->
      </div>

    </b-card>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import ContentWorkFlo           from './components/WorkFloContent.vue'
import GenericCard              from './Card.vue'
import moment                   from 'moment'
import i18n                     from '../../libs/i18n'
import { capitalize }                                     from '../../utils/filter'
import { clone, initials, escapeHtml, initialsFromEmail } from '../../utils/utils'
import EditButton                                         from '../button/Edit.vue'
import RemoveButton             from '../button/Remove.vue'
import VClamp from 'vue-clamp'

export default {
  components: { GenericCard, ContentWorkFlo, EditButton, RemoveButton, VClamp },
  props: {
    email: {
      type: Object,
      default: () => {}
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    // isDeletable: {
    //   type: Boolean,
    //   default: true
    // },
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(clone(props.email))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const check = ref(false)
    const isHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const iconsWidth = computed(() => {
      let output = 0
      let iconWidth = 28

      // if (props.email.documents.length > 0) {
      //   output += iconWidth
      // }
      // if (props.email.notes != null) {
      //   output += iconWidth
      // }
      // if (props.email.steps.length > 0) {
      //   output += iconWidth
      // }
      // if (props.email.workFlo != null || props.email.workflow != null) {
      //   output += iconWidth
      // }

      return output + 'px'
    })



    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(check, val => {
    //   emit('checkReminder')
    // })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      escapeHtml,

      // Data
      check,
      isHovered,

      // Computed
      iconsWidth,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { initialsFromEmail, initials, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>
.badge:empty {
  display: block;
  width: 20px;
  height: 20px;
}
</style>