<template>
  <div>
    <calendar
        :allow-add="false"
        ref="calendarRef"

        @fetchEvents="fetchEvents"
        @eventClick="eventClick"
        @dateClick="dateClick"
        @selectDateRange="selectDateRange"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import Calendar from '../../../components/calendar2/Calendar.vue'
import store from '../../../store'
import { isEmptyObject } from '../../../utils/utils'

export default {
  components: {
    Calendar
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, {emit}) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const calendarRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.workFlo, val => {
      calendarRef.value.refetchEvents()
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const fetchEvents = (selectedCalendars, info, successCallback) => {
      // console.log(events.value.filter(event => selectedCalendars.includes(event.extendedProps.calendar.label)))
      // successCallback(events.value.filter(event => selectedCalendars.includes(event.extendedProps.calendar.label)))

      if (!isEmptyObject(props.workFlo)) {
        successCallback(props.workFlo._calendarEvents.filter(event => selectedCalendars.includes(event.extendedProps.calendar.label)))
      } else {
        successCallback([])
      }
    }

    store.commit('calendar2/SET_SELECTED_CALENDARS', ['relatedDocument', 'task', 'call', 'event', 'intervention'])
    store.commit('calendar2/SET_CALENDAR_OPTIONS', [
      {
        color: 'primary',
        label: 'task',
        icon: {
          icon: 'check',
          library: 'fas'
        }
      },
      {
        color: 'warning',
        label: 'call',
        icon: {
          icon: 'phone',
          library: 'fas'
        }
      },
      {
        color: 'success',
        label: 'event',
        icon: {
          icon: 'calendar-alt',
          library: 'fas'
        }
      },
      {
        color: 'info',
        label: 'intervention',
        icon: {
          icon: 'road',
          library: 'fas'
        }
      },      {
        color: 'danger',
        label: 'relatedDocument',
        icon: {
          icon: 'folder',
          library: 'fas'
        }
      },
    ])

    const eventClick = (calendarEvent) => {
      // console.log("eventClick", calendarEvent)

      if (calendarEvent.extendedProps.calendar.label == "task") {
        // console.log("task", calendarEvent.extendedProps.entity)
        emit('updateTask', calendarEvent.extendedProps.entity)
      } else if (calendarEvent.extendedProps.calendar.label == "call") {
        // console.log("call", calendarEvent.extendedProps.entity)
        emit('updateCall', calendarEvent.extendedProps.entity)
      } else if (calendarEvent.extendedProps.calendar.label == "event") {
        // console.log("event", calendarEvent.extendedProps.entity)
        emit('updateEvent', calendarEvent.extendedProps.entity)
      } else if (calendarEvent.extendedProps.calendar.label == "intervention") {
        // console.log("intervention", calendarEvent.extendedProps.entity)
        emit('updateIntervention', calendarEvent.extendedProps.entity)
      } else if (calendarEvent.extendedProps.calendar.label == "relatedDocument") {
      }
    }

    const dateClick = (calendarEvent) => {
      // console.log("dateClick", calendarEvent)
    }

    const selectDateRange = (calendarEvent) => {
      // console.log("selectDateRange", calendarEvent)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      calendarRef,

      // Computed

      // Methods
      fetchEvents,
      eventClick,
      dateClick,
      selectDateRange,
    }
  },
  data
      () {
    return {}
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  methods: {}
  ,
  mounted () {
  }
  ,
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>