<template>
  <div>
    <b-row>

      <!--      Title-->
      <b-col md="6">
        <field-input
            :model.sync="workFloTaskLocal.title"
            name="title"
            rules="required"
        />
      </b-col>


      <!--      Mobile : performed -->
      <b-col
          cols="12"
          class="d-block d-md-none"
      >
        <field-switch
            name="performed"
            :model.sync="workFloTaskLocal.performed"
            class="mr-2"
        />
      </b-col>

      <!--      Mobile : type -->
      <b-col
          cols="12"
          class="d-block d-md-none"
      >
        <b-form-group class="workFloTaskFormButtonTypes w-100">
          <template #label>
            <span
                class="d-block"
                style="font-size: 0.857rem; margin-bottom: 0.2857rem;"
            >{{ capitalize($t('type')) }}
            </span>
          </template>

          <b-form-radio-group
              id="radio-group-2"
              v-model="workFloTaskLocal.type"
              button-variant="outline-primary"
              buttons
              name="radio-sub-component"
          >

            <b-form-radio
                value="task"
                class="px-1 py-50"
            >
              <icon icon="check"/>
            </b-form-radio>
            <b-form-radio
                value="call"
                class="px-1 py-50"
            >
              <icon icon="phone"/>
            </b-form-radio>
            <b-form-radio
                value="appointment"
                class="px-1 py-50"
            >
              <icon icon="calendar-alt"/>
            </b-form-radio>
            <b-form-radio
                value="email"
                class="px-1 py-50"
            >
              <icon icon="envelope"/>
            </b-form-radio>
            <b-form-radio
                value="milestone"
                class="px-1 py-50"
            >
              <icon icon="map-marked-alt"/>
            </b-form-radio>

          </b-form-radio-group>
        </b-form-group>
      </b-col>


      <!--      Desktop : performed & type-->
      <b-col
          md="6"
          class="d-none d-md-block"
      >
        <div class="d-flex justify-content-between">
          <field-switch
              name="performed"
              :model.sync="workFloTaskLocal.performed"
              class="mr-2"
          />

          <b-form-group class="workFloTaskFormButtonTypes w-100">
            <template #label>
              <span
                  class="d-block"
                  style="font-size: 0.857rem; margin-bottom: 0.2857rem;"
              >{{ capitalize($t('type')) }}
              </span>
            </template>

            <b-form-radio-group
                id="radio-group-2"
                v-model="workFloTaskLocal.type"
                button-variant="outline-primary"
                buttons
                name="radio-sub-component"
            >

              <b-form-radio
                  value="task"
                  class="px-1 py-50"
              >
                <icon icon="check"/>
              </b-form-radio>
              <b-form-radio
                  value="call"
                  class="px-1 py-50"
              >
                <icon icon="phone"/>
              </b-form-radio>
              <b-form-radio
                  value="appointment"
                  class="px-1 py-50"
              >
                <icon icon="calendar-alt"/>
              </b-form-radio>
              <b-form-radio
                  value="email"
                  class="px-1 py-50"
              >
                <icon icon="envelope"/>
              </b-form-radio>
              <b-form-radio
                  value="milestone"
                  class="px-1 py-50"
              >
                <icon icon="map-marked-alt"/>
              </b-form-radio>

            </b-form-radio-group>
          </b-form-group>
        </div>


      </b-col>

      <!--      Date-->
      <b-col md="6">
        <field-date
            :model.sync="workFloTaskLocal.date"
            name="date"
        />
      </b-col>

      <!--      linkedContact-->
      <b-col md="6">
        <field-select2
            name="linkedContact"
            :model.sync="workFloTaskLocal.linkedContact"
            :options="availableLinkedContacts"
        />
      </b-col>

      <!--      assignedTo-->
      <b-col md="12">
        <field-select2
            name="assignedTo"
            :model.sync="workFloTaskLocal.assignedTo"
            :multiple="true"
            :is-required="true"
            :options="autocompleteEmployees"
            :placeholder="capitalize($t('assignTo'))"
        />
      </b-col>

      <!--      description-->
      <b-col md="12">
        <field-textarea
            :model.sync="workFloTaskLocal.description"
            name="description"
        />
      </b-col>


    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../utils/filter'

import store from '../../store'

import FieldInput    from '../input/Input.vue'
import FieldSwitch   from '../input/Switch.vue'
import FieldDate     from '../input/Date.vue'
import FieldSelect2  from '../input/Select2.vue'
import FieldTextarea from '../input/Textarea.vue'
import { isObject }  from '../../utils/utils'
import useAPI        from '../../utils/useAPI'

export default {
  components: {
    FieldInput,
    FieldSwitch,
    FieldDate,
    FieldSelect2,
    FieldTextarea
  },
  props: {
    workFloTask: {
      type: Object,
      default: () => {}
    },
    workFlo: {
      type: Object,
      default: () => {}
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const availableLinkedContacts = ref([])
    const availableAssignedTo = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteEmployees } = useAPI()

    const workFloTaskLocal = computed({
      get () {
        return props.workFloTask
      },
      set (val) {
        emit('update:workFloTask', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const populateAvailableLinkedContacts = () => {
      console.log(props.workFlo.company.id)
      store.dispatch('user2/getAutocompleteUsers', {
        filters: [
          {
            sectionLogicOperator: 'AND',
            sectionFilters: [
              {
                logicOperator: 'AND',
                field: 'company',
                operator: 'equal',
                value: props.workFlo.company.id
              }
            ]
            // groupOperator: 'AND',
            // group: [
            //   {
            //     field: 'company',
            //     comparator: 'equal',
            //     value:props.workFlo.company.id
            //   }
            // ]
          }
        ]
        // searchCriteria: [{
        //   column_name: 'company',
        //   comparator: '=',
        //   search_text: props.workFlo.company.id
        // }]
      }).then(response => {
        availableLinkedContacts.value = response.results
      })
    }

    // const populateAvailableAssignedTo = () => {
    //   store.dispatch('user2/getAutocompleteEmployees', {
    //     // searchCriteria: [{
    //     //   column_name: 'invitationCode',
    //     //   comparator: 'notNull'
    //     // }, {
    //     //   column_name: 'password',
    //     //   comparator: 'notNull'
    //     // }]
    //   }).then(response => {
    //     availableAssignedTo.value = response
    //   })
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    populateAvailableLinkedContacts()
    // populateAvailableAssignedTo()

    return {
      // Components
      capitalize,

      // Data
      availableLinkedContacts,
      // availableAssignedTo,

      // Computed
      workFloTaskLocal,
      autocompleteEmployees,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.workFloTaskFormButtonTypes > legend {
  padding-bottom: 0;
}
</style>