<template>
  <right-bottom-popup
      :is-open.sync="isOpenLocal"

      @send="$refs.submitNoteFormRef.click()"
  >
    <template #title>
      <span v-if="'id' in noteLocal">{{ capitalize($t('editNote')) }}</span>
      <span v-else>{{ capitalize($t('newNote')) }}</span>
    </template>

    <template #content>
      <validation-observer
          ref="observerRef"
          #default="{handleSubmit, errors}">
        <b-form @submit.prevent="handleSubmit(submitValidatedNoteLocal)">
          <work-flo-note-form
              :work-flo-note.sync="noteLocal"
              :formErrors="errors"
          />
          <button ref="submitNoteFormRef"
                  class="d-none"></button>
        </b-form>
      </validation-observer>
    </template>

  </right-bottom-popup>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import {capitalize} from '../../utils/filter'

import RightBottomPopup from './RightBottomPopup.vue'
import WorkFloNoteForm from '../form/WorkFloNoteForm.vue'

export default {
  components: {
    RightBottomPopup,
    ValidationObserver,
    WorkFloNoteForm,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    note: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const submitNoteFormRef = ref()

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isOpenLocal = computed({
      get () {
        return props.isOpen
      },
      set (val) {
        emit('update:isOpen', val)
      }
    })

    const noteLocal = computed({
      get () {
        return props.note
      },
      set (val) {
        emit('update:note', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const submitValidatedNoteLocal = () => {
      emit('submitValidated')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      submitNoteFormRef,

      // Computed
      isOpenLocal,
      noteLocal,

      // Methods
      submitValidatedNoteLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
    // console.log(this.$refs)
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>