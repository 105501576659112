<template>
  <div>
    <div
        class="separator"
        :class="color==''&&variant!='secondary'?'border-top-'+variant:''"
        :style="color!= ''?'border-top: 1px solid '+color+';':(variant=='secondary')?'border-top: 1px solid #ebe9f1;':''"
    >
      <div
          class="separator-content my-auto"
          :class="color==''&&variant!='secondary'?'text-'+variant:''"
          :style="styleCss"
      >
        <icon
            v-if="icon != ''"
            :icon="icon"
            class="mr-1"
        />
        <span>{{ title.toUpperCase() }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: '#F8F8F8FF'
    },
    variant: {
      type: String,
      default: 'secondary'
    },
    color: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const styleCss = computed(() => {
      return 'background: ' + props.background + ';'
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components

      // Data

      // Computed
      styleCss,

      // Methods

    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.separator {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;

  .separator-content {
    font-weight: bold;
    font-size: 0.85rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>