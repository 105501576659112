<template>
  <div>
    <b-row>
      <b-col md="10">
        <field-input
            name="title"
            rules="required"
            :model.sync="workFloEventLocal.title"
        />
      </b-col>

      <!--            &lt;!&ndash;          All day&ndash;&gt;-->
      <!--            <b-col md="2">-->
      <!--                <span>{{ capitalize($t('allDay')) }}</span>-->
      <!--                <b-form-checkbox-->
      <!--                        v-model="workFloEventLocal.isAllDay"-->
      <!--                        class="custom-control-primary mb-1 mb-md-0"-->
      <!--                        switch-->
      <!--                >-->
      <!--                </b-form-checkbox>-->
      <!--            </b-col>-->

      <!--          Online meeting-->
      <b-col md="2">
        <span>{{ capitalize($t('onlineMeeting')) }}</span>
        <b-form-checkbox
            v-model="workFloEventLocal.isOnlineMeeting"
            class="custom-control-primary mb-1 mb-md-0"
            switch
            @change="onlineMeetingChange"
        >
        </b-form-checkbox>
      </b-col>

      <b-col md="6">
        <field-date
            name="startDate"
            rules="required"
            :model.sync="workFloEventLocal.startDate"
            :enableTime="true"

            @input="setEndDate"
        />
      </b-col>
      <b-col md="6">
        <field-date
            name="endDate"
            rules="required"
            :model.sync="workFloEventLocal.endDate"
            :min-date="workFloEventLocal.startDate"
            :enableTime="true"
        />
      </b-col>

      <!--      Members-->
      <b-col md="6">
        <field-select
            :model.sync="workFloEventLocal.additionalMembers"
            :multiple="true"
            :name="capitalize($t('colleague'))"
            :options="autocompleteEmployees"
        />
      </b-col>

      <!--      Include-->
      <b-col md="6">

        <b-form-group
            label-for="workFloEvent_include"
            :label="capitalize($t('include'))"
            class="w-100"
        >
          <v-select
              id="workFloEvent_include"
              multiple
              taggable
              push-tags
              :placeholder="capitalize($t('addPeople'))"
              :label="capitalize($t('include'))"
              :options="availableLinkedContacts"
              v-model="workFloEventLocal.guests"
          />
        </b-form-group>


      </b-col>
    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

import useAPI from '../../utils/useAPI'
import store from '../../store'
import vSelect from 'vue-select'
import moment from 'moment'

import FieldInput from '../input/Input.vue'
import FieldDate from '../input/Date.vue'
import FieldSelect from '../input/Select2.vue'
import { isObject } from '../../utils/utils'

export default {
  components: {
    FieldSelect,
    FieldInput,
    FieldDate,
    vSelect
  },
  props: {
    workFloEvent: {
      type: Object,
      default: () => {}
    },
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const availableLinkedContacts = ref([])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteEmployees, autocompleteUsers } = useAPI()

    const workFloEventLocal = computed({
      get () {
        console.log(props.workFloEvent)
        return props.workFloEvent
      },
      set (val) {
        emit('update:workFloEvent', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(workFloEventLocal, val => {
      console.log(val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const onlineMeetingChange = (value) => {
      if (value === true) {
        workFloEventLocal.value.type = 'visio'
      } else {
        workFloEventLocal.value.type = 'default'
      }
    }

    const populateAvailableLinkedContacts = () => {
      store.dispatch('user2/getAutocompleteUsers', {
        filters: [
          {
            sectionLogicOperator: 'AND',
            sectionFilters: [
              {
                logicOperator: "AND",
                field: 'company',
                operator: 'equal',
                value: props.workFlo.company.id
              }
            ]
          }
        ]
      }).then(response => {
        // availableLinkedContacts.value = response.results
        availableLinkedContacts.value = response.results.map(x => x.email)
      })
    }

    const setEndDate = () => {
      let end = moment(workFloEventLocal.value.startDate)
      end.add(1, 'hours')
      workFloEventLocal.value.endDate = end.toISOString()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    populateAvailableLinkedContacts()

    return {
      // Components
      capitalize,

      // Data
      availableLinkedContacts,

      // Computed
      autocompleteEmployees,
      autocompleteUsers,
      workFloEventLocal,

      // Methods
      onlineMeetingChange,
      setEndDate,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>