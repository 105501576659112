<template>
  <div>
    <collapse
        accordion
        type="margin"
    >
      <collapse-item
          :title="capitalize($t('toDo')) + ' ('+unPerformedWorkFloTask.length+')'"
          :is-visible="true"
          collapse-class="darkCollapse"
      >
        <small v-if="unPerformedWorkFloTask.length == 0">
          <i>{{ capitalize($t('noTasks')) }}</i>
        </small>
        <work-flo-task-card
            v-for="(wft, index) in unPerformedWorkFloTask"
            :key="'unPerformedWorkFloTask-'+index"
            :work-flo-task="wft"
            :is-calendar-disabled="true"

            @checkClick="checkWorkFloTask(wft)"
            @editClick="editWorkFloTask(wft)"
            @trashClick="removeWorkFloTask(wft)"
        />


      </collapse-item>
      <collapse-item
          :title="capitalize($t('done')) + ' ('+performedWorkFloTask.length+')'"
          collapse-class="darkCollapse"
      >
        <small v-if="performedWorkFloTask.length == 0">
          <i>{{ capitalize($t('noTasks')) }}</i>
        </small>
        <work-flo-task-card
            v-for="(wft, index) in performedWorkFloTask"
            :key="'permormedWorkFloTaskCard-'+index"
            :work-flo-task="wft"
            :is-calendar-disabled="true"

            @checkClick="checkWorkFloTask(wft)"
            @editClick="editWorkFloTask(wft)"
            @trashClick="removeWorkFloTask(wft)"
        />
      </collapse-item>

    </collapse>

    <work-flo-task-prompt
        :is-open.sync="isWorkFloTaskPromptOpen"
        :work-flo-task.sync="currentWorkFloTask"
        :work-flo="workFlo"
        :title="('id' in currentWorkFloTask)?$t('EditWorkFloTask'):$t('NewWorkFloTask')"

        @submitValidated="submitValidatedWorkFloTaskLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'

import store from '../../../store'
import moment from 'moment'

import Collapse from '../../../components/collapse/Collapse.vue'
import CollapseItem from '../../../components/collapse/CollapseItem.vue'
import WorkFloTaskPrompt from '../../../components/prompt/WorkFloTaskPrompt.vue'
import WorkFloTaskCard from '../../../components/card/WorkFloTaskCard.vue'

export default {
  components: {
    WorkFloTaskPrompt,
    Collapse,
    CollapseItem,
    WorkFloTaskCard,
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isWorkFloTaskPromptOpen = ref(false)
    const currentWorkFloTask = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    const unPerformedWorkFloTask = computed(() => {
      if ('workFloTasks' in workFloLocal.value) {
        return workFloLocal.value.workFloTasks.filter(wft => wft.performed === false).sort((a, b) => moment(a.date) - moment(b.date))
      } else {
        return []
      }

    })

    const performedWorkFloTask = computed(() => {
      if ('workFloTasks' in workFloLocal.value) {
        return workFloLocal.value.workFloTasks.filter(wft => wft.performed === true).sort((a, b) => moment(a.date) - moment(b.date))
      } else {
        return []
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isWorkFloTaskPromptOpen, val => {
      if (val === false) {
        resetCurrentWorkFloTask()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const openPrompt = () => {
      isWorkFloTaskPromptOpen.value = true
    }

    const resetCurrentWorkFloTask = () => {
      currentWorkFloTask.value = JSON.parse(JSON.stringify(store.getters['workFlo/getEmptyWorkFloTask']))
    }

    const submitValidatedWorkFloTaskLocal = () => {
      if ('id' in currentWorkFloTask.value) {
        let index = workFloLocal.value.workFloTasks.findIndex(i => i.id === currentWorkFloTask.value.id)
        workFloLocal.value.workFloTasks.splice(index, 1, JSON.parse(JSON.stringify(currentWorkFloTask.value)))
      } else {
        workFloLocal.value.workFloTasks.push(JSON.parse(JSON.stringify(currentWorkFloTask.value)))
      }

      emit('updateWorkFlo')

      isWorkFloTaskPromptOpen.value = false
    }

    const checkWorkFloTask = (workFloTask) => {
      workFloTask.performed = !workFloTask.performed
      emit('updateWorkFlo')
    }

    const editWorkFloTask = (workFloTask) => {
      currentWorkFloTask.value = workFloTask
      isWorkFloTaskPromptOpen.value = true
    }

    const removeWorkFloTask = (workFloTask) => {
      let index = workFloLocal.value.workFloTasks.findIndex(i => i.id === workFloTask.id)
      workFloLocal.value.workFloTasks.splice(index, 1)

      emit('updateWorkFlo')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetCurrentWorkFloTask()

    return {
      // Components
      capitalize,

      // Data
      isWorkFloTaskPromptOpen,
      currentWorkFloTask,

      // Computed
      unPerformedWorkFloTask,
      performedWorkFloTask,

      // Methods
      openPrompt,
      submitValidatedWorkFloTaskLocal,
      checkWorkFloTask,
      editWorkFloTask,
      removeWorkFloTask,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>