<template>
  <div>

    <b-card>
      <div class="mb-2 position-relative w-100 d-flex justify-content-center">
        <div
            class="position-absolute border-bottom w-100"
            style="top: 10px; z-index: 0"
        ></div>

        <div
            class="d-flex bg-white px-2"
            style="z-index: 10"
        >
          <icon
              icon="calendar-day"
              class="mr-50"
          />
          <div>
            <b>{{ ($t('comingSoon')).toUpperCase() }} ({{historyLocal.comingSoonCount}})</b>
          </div>
        </div>

      </div>

      <history-timeline
          :history="historyLocal.comingSoon"

          @activityFunctionCalled="$emit('activityFunctionCalled', $event)"
      />
    </b-card>

    <b-card>
      <div class="mb-2 w-100 d-flex justify-content-center position-relative">
        <div
            class="position-absolute border-bottom w-100"
            style="top: 10px; z-index: 0"
        ></div>

        <div
            class="d-flex bg-white px-2"
            style="z-index: 10"
        >
          <icon
              icon="history"
              class="mr-50"
          />
          <div>
            <b>{{ ($t('history')).toUpperCase() }} ({{historyLocal.historyCount}})</b>
          </div>
        </div>
      </div>

      <history-timeline
          :history="historyLocal.history"

          @activityFunctionCalled="$emit('activityFunctionCalled', $event)"
      />
    </b-card>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import Timeline                 from '../../../components/timeline/Timeline.vue'
import TimeLineItem             from '../../../components/timeline/TimeLineItem.vue'
import ReminderCard             from '../../../components/card/ReminderCard.vue'
import { capitalize }           from '../../../utils/filter'
import { clone }                from '../../../utils/utils'
import moment                   from 'moment'
import i18n                     from '../../../libs/i18n'
import EventCard                from '../../../components/card/EventCard.vue'
import OfferCard                from '../../../components/card/OfferCard.vue'
import useAPI                   from '../../../utils/useAPI'
import ValidatedOfferCard       from '../../../components/card/ValidatedOfferCard.vue'
import OutgoingInvoiceCard      from '../../../components/card/OutgoingInvoiceCard.vue'
import IncomingInvoiceCard      from '../../../components/card/IncomingInvoiceCard.vue'
import WorkFloNoteHistoryCard   from '../../../components/card/WorkFloNoteHistoryCard.vue'
import HistoryTimeline          from './_HistoryTimeline.vue'

export default {
  components: {
    WorkFloNoteHistoryCard,
    IncomingInvoiceCard,
    OutgoingInvoiceCard,
    ValidatedOfferCard,
    OfferCard,
    EventCard,
    ReminderCard,
    TimeLineItem,
    Timeline,
    HistoryTimeline
  },
  props: {},
  setup (props) {
    // console.log(clone(props.history))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { workFloHistory } = useAPI()

    const historyLocal = computed(() => {
      return workFloHistory.value
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    // const displayDate = (dateStr) => {
    //   let now = moment().hour(0).minute(0).seconds(0)
    //   let tomorrow = moment(clone(now)).add(1, 'days')
    //
    //   if (now.format('YYYY-MM-DD') == dateStr) {
    //     return capitalize(i18n.t('today'))
    //   } else if (tomorrow.format('YYYY-MM-DD') == dateStr) {
    //     return capitalize(i18n.t('tomorrow'))
    //   } else {
    //     return capitalize(moment(dateStr).format('LL'))
    //   }
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      historyLocal,

      // Methods
      // displayDate,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>