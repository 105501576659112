<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="lg"
      scrollable
      :hide-footer="hideFooter"
      :ok-title="capitalize($t('validate'))"
      :cancel-title="capitalize($t('cancel'))"
      @hide="tryCloseWorkFloEventModal"
      @hidden="handleCancel"
      @ok="handleOk"
  >
    <validation-observer
        ref="observerRef"
        #default="{handleSubmit, errors}">
      <b-form @submit.prevent="handleSubmit(submitValidatedWorkFloEventLocal)">
        <work-flo-event-form
            :work-flo-event="workFloEventLocal"
            :work-flo="workFlo"
            :formErrors="errors"
        />
        <button ref="submitWorkFloEventFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { capitalize } from '../../utils/filter'

import useAPI from '../../utils/useAPI'
import WorkFloEventForm from '../form/WorkFloEventForm.vue'

export default {
  components: {
    ValidationObserver,
    WorkFloEventForm
  },
  props: {
    workFloEvent: {
      type: Object,
      default: () => {}
    },
    workFlo: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const workFloEventLocal = ref(props.workFloEvent)
    const submitWorkFloEventFormRef = ref(null)
    const observerRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(workFloEventLocal, (val) => {
      emit('update:workFloEvent', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseWorkFloEventModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitWorkFloEventFormRef.value.click()
      }
    }

    const submitValidatedWorkFloEventLocal = () => {
      emit('submitValidated')
    }

    const handleOk = async (bvModalEvent) => {
      bvModalEvent.preventDefault()

      const isValid = await observerRef.value.validate()
      if (isValid) {
        submitValidatedWorkFloEventLocal()
      }
    }

    const handleCancel = () => {}

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      workFloEventLocal,
      submitWorkFloEventFormRef,
      observerRef,

      // Computed
      hideFooter,

      // Methods
      tryCloseWorkFloEventModal,
      submitValidatedWorkFloEventLocal,
      handleOk,
      handleCancel,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    workFloEvent: function (val) {
      this.workFloEventLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>