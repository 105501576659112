<template>
    <div>
        <b-row>
            <b-col md="2">
                <switch-field
                        name="outgoingCall"
                        :model.sync="workFloCallLocal.outgoingCall"
                />
            </b-col>


            <b-col md="5">
                <select2
                        name="resultType"
                        :model.sync="workFloCallLocal.resultType"
                        :options="listOfResultTypes"
                        :clearable="false"
                        label="display"
                        reduce="value"
                />
            </b-col>

            <b-col md="5">
                <select2
                        name="contact"
                        :model.sync="workFloCallLocal.contact"
                        :options="listOfContacts"
                />
            </b-col>

            <b-col md="6">
                <date-field
                        name="date"
                        :model.sync="workFloCallLocal.date"
                        :enable-time="true"
                        :rules="'required'"
                />
            </b-col>

            <b-col md="6">
                <time-field
                        name="duration"
                        :model.sync="workFloCallLocal.duration"
                        :minutes-step="10"
                />
            </b-col>

            <b-col md="12">
                <textarea-field
                        name="note"
                        :model.sync="workFloCallLocal.note"
                />
            </b-col>

        </b-row>


    </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

import store from '../../store'
import i18n from '../../libs/i18n'

import SwitchField from '../input/Switch.vue'
import Select2 from '../input/Select2.vue'
import DateField from '../input/Date.vue'
import TimeField from '../input/Time.vue'
import TextareaField from '../input/Textarea.vue'

export default {
  components: {
    Select2,
    SwitchField,
    DateField,
    TimeField,
    TextareaField,
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    },
    workFloCall: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const listOfContacts = ref([])
    const listOfResultTypes = [
      {
        display: capitalize(i18n.t('successful call')),
        value: 'successful call'
      },
      {
        display: capitalize(i18n.t('no response')),
        value: 'no response'
      },
      {
        display: capitalize(i18n.t('busy')),
        value: 'busy'
      },
      {
        display: capitalize(i18n.t('wrong number')),
        value: 'wrong number'
      },
      {
        display: capitalize(i18n.t('voice message')),
        value: 'voice message'
      },
      {
        display: capitalize(i18n.t('interrupted call')),
        value: 'interrupted call'
      },
    ]

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloCallLocal = computed({
      get () {
        return props.workFloCall
      },
      set (val) {
        emit('update:workFloCall', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    store.dispatch('user2/getAutocompleteUsers', {
      filters: [
        {
          // group: [
          //   {
          //     field: 'company',
          //     comparator: 'equal',
          //     value: props.workFlo.company.id
          //   }
          // ]
          sectionLogicOperator: 'AND',
          sectionFilters: [
            {
              logicOperator: 'AND',
              field: 'company',
              operator: 'equal',
              value: props.workFlo.company.id
            }
          ]
        }
      ]
    }).then(response => {
      listOfContacts.value = response.results
    })

    return {
      // Components

      // Data
      listOfContacts,
      listOfResultTypes,

      // Computed
      workFloCallLocal,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>