<template>
    <div>
        <work-flo-intervention-card
                v-for="(intervention, index) in workFloLocal.interventions"
                :key="'workFloIntervention-'+index"
                :work-flo-intervention="intervention"

                @editClick="editWorkFloIntervention(intervention)"
                @trashClick="removeWorkFloIntervention(intervention)"
        />

        <work-flo-intervention-prompt
                :is-open.sync="isWorkFloInterventionPromptOpen"
                :work-flo-intervention.sync="currentWorkFloIntervention"
                :title="('id' in currentWorkFloIntervention)?$t('EditWorkFloIntervention'):$t('NewWorkFloIntervention')"

                @submitValidated="submitValidatedWorkFloInterventionLocal"
        />
    </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import store from '../../../store'

import WorkFloInterventionCard from '../../../components/card/WorkFloInterventionCard.vue'
import WorkFloInterventionPrompt from '../../../components/prompt/WorkFloInterventionPrompt.vue'

export default {
  components: {
    WorkFloInterventionCard,
    WorkFloInterventionPrompt,
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isWorkFloInterventionPromptOpen = ref(false)
    const currentWorkFloIntervention = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isWorkFloInterventionPromptOpen, val => {
      if (val === false) {
        resetCurrentWorkFloIntervention()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const openPrompt = () => {
      isWorkFloInterventionPromptOpen.value = true
    }

    const resetCurrentWorkFloIntervention = () => {
      currentWorkFloIntervention.value = JSON.parse(JSON.stringify(store.getters['workFlo/getEmptyWorkFloIntervention']))
      currentWorkFloIntervention.value.workFlo = workFloLocal.value.id
      currentWorkFloIntervention.value.type = 'site'
      // console.log(JSON.parse(JSON.stringify(currentWorkFloIntervention.value)))
    }

    const submitValidatedWorkFloInterventionLocal = () => {
      if ('id' in currentWorkFloIntervention.value) {
        let index = workFloLocal.value.interventions.findIndex(e => e.id === currentWorkFloIntervention.value.id)
        workFloLocal.value.interventions.splice(index, 1, JSON.parse(JSON.stringify(currentWorkFloIntervention.value)))
      } else {
        workFloLocal.value.interventions.push(JSON.parse(JSON.stringify(currentWorkFloIntervention.value)))
      }

      // console.log(JSON.parse(JSON.stringify(currentWorkFloIntervention.value)))

      emit('updateWorkFlo')

      isWorkFloInterventionPromptOpen.value = false
    }

    const editWorkFloIntervention = (intervention) => {
      currentWorkFloIntervention.value = JSON.parse(JSON.stringify(intervention))
      isWorkFloInterventionPromptOpen.value = true
    }
    const removeWorkFloIntervention = (intervention) => {
      let index = workFloLocal.value.interventions.findIndex(i => i.id === intervention.id)
      workFloLocal.value.interventions.splice(index, 1)

      emit('updateWorkFlo')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetCurrentWorkFloIntervention()

    return {
      // Components

      // Data
      isWorkFloInterventionPromptOpen,
      currentWorkFloIntervention,

      // Computed
      workFloLocal,

      // Methods
      openPrompt,
      submitValidatedWorkFloInterventionLocal,
      editWorkFloIntervention,
      removeWorkFloIntervention,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>