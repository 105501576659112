<template>
  <div>
    <emails-base
        :mail-folders="mailsFolders"
        :selected-folder.sync="selectedFolder"
        :display-classification="false"

        @getEmails="readMailsLocal"
    />


  </div>
</template>

<script>
import { ref, computed, watch }       from '@vue/composition-api'
import store                          from '../../../../store'
import { getUserData, isEmptyObject } from '../../../../utils/utils'
import EmailsBase                     from '../../../../components/email/v2/EmailsBase.vue'
import useAPI                         from '../../../../utils/useAPI'

export default {
  components: { EmailsBase },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const mailsFolders = ref([
      {
        badge: 0,
        emails: [],
        icon: 'box',
        id: 1,
        parentId: null,
        subFolders: [],
        title: 'Boîte de réception'
      },
      {
        badge: 0,
        emails: [],
        icon: 'paper-plane',
        id: 2,
        parentId: null,
        subFolders: [],
        title: 'Éléments envoyés'
      },
    ])
    const selectedFolder = ref({
      email: getUserData().email,
      folderId: 1
    })

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const readMailsLocal = () => {
      if (!isEmptyObject(props.workFlo) && ('id' in props.workFlo)) {
        store.dispatch('workFlo/getWorkFloMails', {
          workFloId: props.workFlo.id,
          search: null
        })
            .then(response => {
              // console.log(response)
              // console.log(selectedFolder.value)

              if (selectedFolder.value.folderId == 2) {
                let emails = response.sent
                emails.forEach(e => e.folder.id = 2)
                // console.log(emails)

                store.commit('email/SET_EMAILS', emails)
              } else {
                let emails = response.inbox
                emails.forEach(e => e.folder.id = 1)
                // console.log(emails)
                store.commit('email/SET_EMAILS', emails)
              }
            })
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components

      // Data
      mailsFolders,
      selectedFolder,

      // Computed

      // Methods
      readMailsLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>