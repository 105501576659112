<template>
  <div>
    <b-card
        no-body
        class="overflow-hidden p-1"
        @mouseover="isHovered=true"
        @mouseleave="isHovered=false"
        @click="$emit('click')"
    >
      <!--              Documents-->
      <div
          v-if="note.documents"
          class="mt-2"
      >
        <b-row>
          <b-col
              md="3"
              v-for="(document, i) in note.documents"
          >
            <document-card
                v-if="isAllDocumentsDisplayed!=true?(i < 4):true"
                :document="document"
                :is-deletable="false"
                :small="true"

                @click="downloadByDocument(document)"
                @download="downloadByDocument(document)"
            />
          </b-col>
        </b-row>

        <div class="d-flex">
          <div
              v-if="note.documents.length >= 4 && isAllDocumentsDisplayed == false"
              class="cursor-pointer"

              @click="isAllDocumentsDisplayed = true"
          >
            <icon
                icon="angle-double-down"
                class="mr-50"
            />
            <span>
              {{ capitalize($t('displayNumberAttachments', { number: note.documents.length })) }}
            </span>
          </div>

<!--          <div-->
<!--              v-if="note.documents.length >= 2"-->
<!--              class="ml-1 cursor-pointer"-->

<!--              @click="downloadAllDocumentsLocal"-->
<!--          >-->
<!--            <icon-->
<!--                icon="arrow-down"-->
<!--                class="mr-50"-->
<!--            />-->
<!--            <span>-->
<!--              {{ capitalize($t('downloadAll')) }}-->
<!--            </span>-->
<!--          </div>-->
        </div>


      </div>

      <div class="d-flex">
        <div class="w-100">
          <!--          Line 1-->
          <div class="d-flex">
            <span v-html="note.note" />
          </div>

          <!--          Line 2-->
          <div class="d-flex">
            <div class="w-100">
              <div class="d-flex mt-1">

                <div
                    class="d-flex"
                >
                  <b-avatar
                      v-if="note._createdBy != null && note._createdBy.avatar"
                      class="pull-up"
                      :src="note._createdBy.avatar.url"
                      variant="light-primary"
                      size="sm"
                  />
                  <b-avatar
                      v-else
                      class="pull-up"
                      :text="note._createdBy!=null?initials(note._createdBy._display):'?'"
                      variant="light-primary"
                      size="sm"
                  />

                  <div class="ml-50 text-truncate">
                    {{ note._createdBy != null ? note._createdBy._display : '?' }}
                  </div>
                </div>

                <div
                    v-if="isHistory && displayLastUpdate != null"
                    class="d-flex text-secondary text-nowrap ml-4"
                >
                  <icon icon="history"/>

                  <div class="ml-50">
                    <small>
                      <i>{{ displayLastUpdate }}</i>
                    </small>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        <Transition name="slide">
          <div
              v-if="isHovered && (isEditable || isDeletable)"
              class="my-auto d-flex"
          >
            <!--          Edit -->
            <edit-button
                @click.native.stop="$emit('edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :disabled="!isEditable"
                size="sm"
            />

            <!--          Remove -->
            <remove-button
                @click.native.stop="$emit('delete')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :is-disabled="!isDeletable"
                size="sm"
            />
          </div>
        </Transition>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch }         from '@vue/composition-api'
import ViewButton                       from '../button/View.vue'
import EditButton                       from '../button/Edit.vue'
import RemoveButton                     from '../button/Remove.vue'
import { capitalize, currency }         from '../../utils/filter'
import { downloadByDocument, initials } from '../../utils/utils'
import { useCustomerInvoices }          from '../../views/sales/customerInvoice/useCustomerInvoice'
import moment                           from 'moment'
import i18n                             from '../../libs/i18n'
import DocumentCard                     from './Document.vue'

export default {
  components: {
    DocumentCard,
    ViewButton,
    RemoveButton,
    EditButton,
  },
  props: {
    note: {
      type: Object,
      default: () => {}
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const check = ref(false)
    const isHovered = ref(false)
    const isAllDocumentsDisplayed = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const iconsWidth = computed(() => {
      let output = 0
      let iconWidth = 22

      if (props.note.receipt != null) {
        output += iconWidth
      }

      return output + 'px'
    })

    const lastUpdate = computed(() => {
      if (props.note.logs.length == 0) {
        return null
      } else {
        return props.note.logs.slice(-1)[0]
      }
    })

    const displayLastUpdate = computed(() => {
      if (lastUpdate == null || lastUpdate.value.action == 'create') {
        return null
      } else {
        return capitalize(i18n.t('lastUpdateDate', { 'date': moment(lastUpdate.value.at).fromNow() }))
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      moment,

      // Data
      check,
      isHovered,
      isAllDocumentsDisplayed,

      // Computed
      iconsWidth,
      displayLastUpdate,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize, downloadByDocument, initials, currency },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>