<template>
    <div>
        <b-row>
            <b-col md="10">
                <field-input
                        name="title"
                        rules="required"
                        :model.sync="workFloInterventionLocal.title"
                />
            </b-col>

            <!--          All day-->
            <b-col md="2">
                <span>{{ capitalize($t('allDay')) }}</span>
                <b-form-checkbox
                        v-model="workFloInterventionLocal.isAllDay"
                        class="custom-control-primary mb-1 mb-md-0"
                        switch
                >
                </b-form-checkbox>
            </b-col>

            <b-col md="6">
                <field-date
                        name="startDate"
                        rules="required"
                        :model.sync="workFloInterventionLocal.startDate"
                        :enableTime="true"
                />
            </b-col>
            <b-col md="6">
                <field-date
                        name="endDate"
                        rules="required"
                        :model.sync="workFloInterventionLocal.endDate"
                        :enableTime="true"
                />
            </b-col>

            <!--            Type-->
            <b-col md="4"
                   class="mb-1">
                <div class="mb-25">{{ capitalize($t('type')) }} :</div>

                <div class="d-flex">
                    <div
                            @click="resetType();workFloInterventionLocal.type = 'site'"
                            class="cursor-pointer px-1 px-md-2"
                            :class="{'text-primary': workFloInterventionLocal.type == 'site'}"
                            v-b-tooltip.hover.bottom="capitalize($t('site'))"
                    >
                        <icon
                                icon="briefcase"
                                size="2x"
                        />
                    </div>

                    <div
                            @click="resetType();workFloInterventionLocal.type = 'trip'"
                            class="cursor-pointer px-1 px-md-2"
                            :class="{'text-primary': workFloInterventionLocal.type == 'trip'}"
                            v-b-tooltip.hover.bottom="capitalize($t('trip'))"
                    >
                        <icon
                                icon="suitcase-rolling"
                                size="2x"
                        />
                    </div>

                </div>

            </b-col>

            <!--      Members-->
            <b-col md="8">
                <field-select
                        :model.sync="workFloInterventionLocal.additionalMembers"
                        :multiple="true"
                        :name="capitalize($t('colleague'))"
                        :options="autocompleteEmployees"
                />

            </b-col>
        </b-row>

        <form-site
                v-if="workFloInterventionLocal.type == 'site'"
                :event="workFloInterventionLocal"
                class="mb-2"
                :is-required="true"
        />
        <form-trip
                v-if="workFloInterventionLocal.type == 'trip'"
                :event="workFloInterventionLocal"
                :disabled="false"
                class="mt-1"
        />

    </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

import useAPI from '../../utils/useAPI'

import FieldDate from '../input/Date.vue'
import FieldSelect from '../input/Select2.vue'
import FieldInput from '../input/Input.vue'
import FormSite from './event/components/_site.vue'
import FormTrip from './event/components/_trip.vue'

export default {
  components: { FormTrip, FormSite, FieldInput, FieldSelect, FieldDate },
  props: {
    workFloIntervention: {
      type: Object,
      default: () => {}
    },
  },
  setup (props, { emit }) {
    // console.log( console.log(JSON.parse(JSON.stringify(props.workFloIntervention))))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteEmployees } = useAPI()
    const workFloInterventionLocal = computed({
      get () {
        return props.workFloIntervention
      },
      set (val) {
        emit('update:workFloIntervention', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetType = () => {
      workFloInterventionLocal.value.place = null
      workFloInterventionLocal.value.startPlace = null
      workFloInterventionLocal.value.endPlace = null
      workFloInterventionLocal.value.isTripWithPersonalVehicle = false
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      autocompleteEmployees,
      workFloInterventionLocal,

      // Methods
      resetType,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>