<template>

  <b-form-group
      :label-for="name+ (listIndex != -1?(' '+(listIndex+1)):'')"
      :label="displayLabel?capitalize($t(name)):''"
  >
    <validation-provider
        v-if="isRequired"
        #default="{ errors }"
        :name="$tc(name)+ (listIndex != -1?(' '+(listIndex+1)):'')"
        rules="required"
    >
      <b-form-timepicker
          v-model='localModel'
          :disabled="disabled"
          locale='fr-FR'
          :label-no-time-selected="$t('No time selected')"
          :placeholder="$t('No time selected')"
          :minutes-step="minutesStep"
          button-only
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

    <b-form-timepicker
        v-else
        v-model='localModel'
        :disabled="disabled"
        locale='fr-FR'
        :label-no-time-selected="$t('No time selected')"
        :placeholder="$t('No time selected')"
        :minutes-step="minutesStep"
    />
  </b-form-group>

</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { required } from '@/utils/validations/validations'
import { capitalize } from '@/utils/filter'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider
  },
  props: {
    model: {
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minutesStep: {
      type: Number,
      default: 1
    },
    allow0: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(props.model)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref()

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, (val) => {
      if (!props.allow0 && val == '00:00:00') {
        localModel.value = "00:05:00"
      }
      emit('update:model', val)
      emit('input')
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // console.log(props.model.length)
    if (props.model.length == 25) {
      localModel.value = props.model.slice(11, 19)
    } else {
      localModel.value = props.model
    }


    return {
      // Components
      required,
      capitalize,

      // Data
      localModel,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () { },
  created () { }
}
</script>

<style lang="scss">
.b-form-btn-label-control.form-control {
  height: 2.714rem;
}
</style>