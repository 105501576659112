<template>
  <div>
    <b-row>
      <b-col
          md="2"
          class="d-none d-md-flex"
      >
        <div
            v-if="workFloLocal._warnings.length"
            :title="'<ul class=\'text-left\'><li>'+workFloLocal._warnings.join('</li><li>')+'</li></ul>'"
            v-b-tooltip.html
            class="text-nowrap mr-2"
        >
          <icon size="2x" icon="exclamation-triangle"/>
        </div>

        <h2>{{ currency(workFloLocal.expectedTurnover) }}</h2>
      </b-col>

      <b-col
          md="8"
          class="text-center"
      >
        <h2>{{ workFloLocal.title }}</h2>
        <small>{{ workFloLocal.company._display }}</small>

<!--        <vue-slider-->
<!--            v-model="sliderStatus"-->
<!--            :data="availableSliderStatus"-->
<!--            :adsorb="true"-->
<!--            :marks="true"-->
<!--            class="d-none d-md-block mt-1 mx-5"-->
<!--            @input="updateStatus"-->
<!--        />-->

<!--        <select2-->
<!--            :model.sync="sliderStatus"-->
<!--            :options="availableSliderStatus"-->
<!--            :clearable="false"-->
<!--            class="d-block d-md-none"-->

<!--            @input="updateStatus"-->
<!--        />-->

      </b-col>

      <b-col
          md="2"
          class="d-none d-md-block text-right"
      >

<!--        <task-button-->
<!--            :with-border="true"-->
<!--            :with-text="false"-->
<!--            :with-icon="true"-->
<!--            v-b-popover.hover.top="capitalize($t('NewWorkFloTask'))"-->

<!--            @click="$emit('newTask')"-->
<!--        />-->

        <note-button
            :with-border="true"
            :with-text="false"
            :with-icon="true"
            class="ml-1"
            v-b-popover.hover.top="capitalize($t('newNote'))"

            @click="$emit('newNote')"
        />

<!--        <call-button-->
<!--            :with-border="true"-->
<!--            :with-text="false"-->
<!--            :with-icon="true"-->
<!--            class="ml-1"-->
<!--            v-b-popover.hover.top="capitalize($t('NewWorkFloCall'))"-->

<!--            @click="$emit('newCall')"-->
<!--        />-->

        <event-button
            :with-border="true"
            :with-text="false"
            :with-icon="true"
            class="ml-1"
            v-b-popover.hover.top="capitalize($t('NewEvent'))"

            @click="$emit('newEvent')"
        />

<!--        <intervention-button-->
<!--            :with-border="true"-->
<!--            :with-text="false"-->
<!--            :with-icon="true"-->
<!--            class="ml-1"-->
<!--            v-b-popover.hover.top="capitalize($t('NewWorkFloIntervention'))"-->

<!--            @click="$emit('newIntervention')"-->
<!--        />-->

        <button-edit
            :with-icon="true"
            :with-text="false"
            :with-border="true"

            class="ml-1"

            @click="$emit('editWorkFlo')"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
          cols="12"
          v-if="workFloLocal.mainContact"
          class="mt-0 mt-md-4 d-flex justify-content-center"
      >
        <contact-card :contact="workFloLocal.mainContact"/>
        <contact-card
            v-for="(contact, index) in workFloLocal.otherContacts"
            :key="'otherContact-'+index"
            :contact="contact"
            class="ml-2"
        />
      </b-col>
      <b-col
          v-else
          class="mt-2"
      />
    </b-row>

    <!--    Mobile : buttons-->
    <b-row class="d-block d-md-none">
      <b-col
          cols="12"
          class="d-flex justify-content-center"
      >
<!--        <task-button-->
<!--            :with-border="true"-->
<!--            :with-text="false"-->
<!--            :with-icon="true"-->

<!--            @click="$emit('newTask')"-->
<!--        />-->

        <note-button
            :with-border="true"
            :with-text="false"
            :with-icon="true"
            class="ml-1"

            @click="$emit('newNote')"
        />

<!--        <call-button-->
<!--            :with-border="true"-->
<!--            :with-text="false"-->
<!--            :with-icon="true"-->
<!--            class="ml-1"-->

<!--            @click="$emit('newCall')"-->
<!--        />-->

        <event-button
            :with-border="true"
            :with-text="false"
            :with-icon="true"
            class="ml-1"

            @click="$emit('newEvent')"
        />

<!--        <intervention-button-->
<!--            :with-border="true"-->
<!--            :with-text="false"-->
<!--            :with-icon="true"-->
<!--            class="ml-1"-->

<!--            @click="$emit('newIntervention')"-->
<!--        />-->

        <button-edit
            :with-icon="true"
            :with-text="false"
            :with-border="true"

            class="ml-1"

            @click="$emit('editWorkFlo')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../../utils/filter'

import VueSlider          from 'vue-slider-component'
import Select2            from '../../../components/input/Select2.vue'
import ButtonEdit         from '../../../components/button/Edit.vue'
import ContactCard        from '../../../components/card/ContactCard.vue'
import TaskButton         from '../../../components/button/TaskButton.vue'
import NoteButton         from '../../../components/button/NoteButton.vue'
import CallButton         from '../../../components/button/CallButton.vue'
import EventButton        from '../../../components/button/EventButton.vue'
import InterventionButton from '../../../components/button/InterventionButton.vue'

import useAPI from '../../../utils/useAPI'

export default {
  components: {
    VueSlider,
    Select2,
    ButtonEdit,
    ContactCard,
    TaskButton,
    NoteButton,
    CallButton,
    EventButton,
    InterventionButton,
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    },
    isMounted: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(JSON.parse(JSON.stringify(props.workFlo)))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const sliderStatus = ref(props.workFlo.status.name)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteWorkFloStatus } = useAPI()

    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    const availableSliderStatus = computed(() => {
      let availableSliderStatus = []

      autocompleteWorkFloStatus.value.forEach(s => {
        availableSliderStatus.push(s.name)
      })

      return availableSliderStatus
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    watch(() => props.workFlo.status, val => {
      sliderStatus.value = val.name
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateStatus = (val) => {
        // console.log(val)
        // workFloLocal.value.status = autocompleteWorkFloStatus.value.find(s => s.name == val)
        //
        // console.log("A")
        // emit('updateWorkFlo')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      currency,

      // Data
      sliderStatus,

      // Computed
      workFloLocal,
      availableSliderStatus,

      // Methods
      updateStatus,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vue/libs/vue-slider.scss';

</style>