<template>
  <div class="workFloGeneralInformations">
    <b-row class="mb-2">

      <b-col md="12" class="mb-1">
        <h1>{{ capitalize($t('general')) }}</h1>
      </b-col>

      <!--        First column-->
      <b-col md="4">
        <b-form-group
            v-for="(element, index) in firstColumn"
            :key="'workFloColumn1Index_'+index"
            :label="capitalize($t(element.name))"
            :label-for="'workFloInput_'+element.name"
        >
          <b-form-input
              v-if="isReady && element.type != 'multiple'"
              :id="'workFloInput_'+element.name"
              plaintext
              :value="displayInputValue(element)"
              class="text-truncate"
          />
          <b-form-input
              v-else-if="isReady && element.type == 'multiple'"
              :id="'workFloInput_'+element.name"
              plaintext
              :value="displayInputValue(element)"
              class="text-truncate"
              v-b-tooltip.hover.top="displayInputValue(element)"
          />
        </b-form-group>

      </b-col>

      <!--        Second column-->
      <b-col md="4">
        <b-form-group
            v-for="(element, index) in secondColumn"
            :key="'workFloColumn2Index_'+index"
            :label="capitalize($tc(element.name))"
            :label-for="'workFloInput_'+element.name"
        >
          <b-form-input
              v-if="isReady && element.type != 'multiple'"
              :id="'workFloInput_'+element.name"
              plaintext
              :value="displayInputValue(element)"
              class="text-truncate"
          />
          <b-form-input
              v-else-if="isReady && element.type == 'multiple'"
              :id="'workFloInput_'+element.name"
              plaintext
              :value="displayInputValue(element)"
              class="text-truncate"
              v-b-tooltip.hover.top="displayInputValue(element)"
          />
        </b-form-group>
      </b-col>

      <!--        Third column-->
      <b-col md="4">
        <div
            v-for="(element, index) in thirdColumn"
            :key="'workFloColumn3Index_'+index"
        >
          <b-form-group
              v-if="isReady &&
                            element.name != 'prescriberCompany' &&
                            element.name != 'prescriberIndividual' &&
                            element.name != 'businessIntroducerCompany' &&
                            element.name != 'businessIntroducerIndividual' &&
                            element.name != 'stateReason'"
              :label="capitalize($t(element.name))"
              :label-for="'workFloInput_'+element.name"
          >
            <b-form-input
                :id="'workFloInput_'+element.name"
                plaintext
                :value="displayInputValue(element)"
            />
          </b-form-group>

          <b-form-group
              v-else-if="isReady && element.name=='prescriberCompany' && workFlo.prescriberCompany != null"
              :label="capitalize($t('prescriber'))"
              :label-for="'workFloInput_'+element.name"
          >
            <b-form-input
                :id="'workFloInput_'+element.name"
                plaintext
                :value="workFlo.prescriberCompany._display"
            />
          </b-form-group>

          <b-form-group
              v-else-if="isReady && element.name=='prescriberIndividual'"
              :label="capitalize($t('prescriber'))"
              :label-for="'workFloInput_'+element.name"
          >
            <b-form-input
                :id="'workFloInput_'+element.name"
                plaintext
                :value="workFlo.prescriberIndividual==null?'-':workFlo.prescriberIndividual._display"
            />
          </b-form-group>

          <b-form-group
              v-else-if="isReady && element.name=='businessIntroducerCompany' && workFlo.businessIntroducerCompany != null"
              :label="capitalize($t('businessIntroducer'))"
              :label-for="'workFloInput_'+element.name"
          >
            <b-form-input
                :id="'workFloInput_'+element.name"
                plaintext
                :value="workFlo.businessIntroducerCompany._display"
            />
          </b-form-group>

          <b-form-group
              v-else-if="isReady && element.name=='businessIntroducerIndividual'"
              :label="capitalize($t('businessIntroducer'))"
              :label-for="'workFloInput_'+element.name"
          >
            <b-form-input
                :id="'workFloInput_'+element.name"
                plaintext
                :value="workFlo.businessIntroducerIndividual==null?'-':workFlo.businessIntroducerIndividual._display"
            />
          </b-form-group>

          <b-form-group
              v-else-if="isReady && element.name=='stateReason'"
              :label="capitalize($t(element.name))"
              :label-for="'workFloInput_'+element.name"
          >
            <b-form-input
                :id="'workFloInput_'+element.name"
                plaintext
                :value="displayInputValue(element)"
                class="text-truncate"
                v-b-tooltip.hover.top="displayInputValue(element)"
            />
          </b-form-group>

        </div>

      </b-col>


    </b-row>

    <b-row class="mb-2">
      <b-col md="12">
        <h1>{{capitalize($tc('need',2))}}</h1>
      </b-col>
    </b-row>

    <b-row
        v-for="(wfNeed, wfNeedIndex) in workFlo.workFloNeeds"
        :key="wfNeedIndex"
    >
      <b-col md="6">
        <b-form-group
            :label="capitalize($t('article'))"
            :label-for="'workFloNeedArticle_'+wfNeedIndex"
        >
          <b-form-input
              :id="'workFloNeedArticle_'+wfNeedIndex"
              plaintext
              :value="wfNeed.article.name"
              class="text-truncate"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
            :label="capitalize($t('quantity'))"
            :label-for="'workFloNeedQuantity_'+wfNeedIndex"
        >
          <b-form-input
              :id="'workFloNeedQuantity_'+wfNeedIndex"
              plaintext
              :value="wfNeed.quantity"
              class="text-truncate"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'
import { isEmptyObject } from '../../../utils/utils'

import i18n from '../../../libs/i18n'
import moment from 'moment'

export default {
  components: {},
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const firstColumn = [
      {
        name: 'title',
        type: 'text'
      },
      {
        name: 'status',
        type: 'single',
        display: 'name',
        translate: true
      },
      {
        name: 'source',
        type: 'single',
        translate: true
      },
      {
        name: 'exhibition',
        type: 'single',
      },
      {
        name: 'typeOfSale',
        type: 'single',
      },
      {
        name: 'function',
        type: 'single',
      },
      {
        name: 'typeOfApplication',
        type: 'single',
      },
      {
        name: 'needs',
        type: 'multiple',
        display: 'name'
      },
    ]

    const secondColumn = [
      {
        name: 'company',
        type: 'single',
        display: '_display'
      },
      {
        name: 'mainContact',
        type: 'single',
        display: '_display'
      },
      {
        name: 'otherContacts',
        type: 'multiple',
        display: '_display'
      },
      {
        name: 'expectedTurnover',
        type: 'text'
      },
      {
        name: 'typeOfPurchase',
        type: 'single'
      },
      {
        name: 'communication',
        type: 'boolean'
      }
    ]

    const thirdColumn = [
      {
        name: 'assignment',
        type: 'single',
        display: '_display'
      },
      {
        name: 'isPriority',
        type: 'boolean',
      },
      {
        name: 'estimatedClosingDate',
        type: 'date',
      },
      {
        name: 'prescriberCompany',
        type: 'single',
        display: '_display'
      },
      {
        name: 'prescriberIndividual',
        type: 'single',
        display: '_display'
      },
      {
        name: 'businessIntroducerCompany',
        type: 'single',
        display: '_display'
      },
      {
        name: 'businessIntroducerIndividual',
        type: 'single',
        display: '_display'
      },
      {
        name: 'distributor',
        type: 'single',
        display: '_display'
      },
      {
        name: 'state',
        type: 'single',
        translate: true
      },
      {
        name: 'stateReason',
        type: 'textarea'
      },
    ]

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isReady = computed(() => {
      return !isEmptyObject(props.workFlo)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const displayInputValue = (inputElement) => {
      if (inputElement.type == 'multiple') {
        if (props.workFlo[inputElement.name] != null) {
          if ('display' in inputElement) {
            return props.workFlo[inputElement.name].map((e) => e[inputElement.display]).join(', ')
          } else if (props.workFlo[inputElement.name] != null) {
            return props.workFlo[inputElement.name]
          } else {
            return '-'
          }
        } else {
          return '-'
        }
      } else if (inputElement.type == 'boolean') {
        return capitalize(i18n.t(props.workFlo[inputElement.name] ? 'yes' : 'no'))
      } else if (inputElement.type == 'date') {
        return props.workFlo[inputElement.name] == null ? '-' : moment(props.workFlo[inputElement.name]).format('LL')
      } else {
        if (props.workFlo[inputElement.name] != null) {
          if ('display' in inputElement) {
            return inputElement.translate ? capitalize(i18n.t(props.workFlo[inputElement.name][inputElement.display])) : props.workFlo[inputElement.name][inputElement.display]
          } else {
            return ('translate' in inputElement && inputElement.translate == true) ? capitalize(i18n.t(props.workFlo[inputElement.name])) : props.workFlo[inputElement.name]
          }
        } else {
          return '-'
        }
      }

    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      firstColumn,
      secondColumn,
      thirdColumn,

      // Computed
      isReady,

      // Methods
      displayInputValue,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.workFloGeneralInformations {
  label {
    margin-bottom: 0px;
    font-weight: bold;
  }

  input {
    padding-top: 0;
  }
}
</style>