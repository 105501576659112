<template>
  <div>
    <b-card
        no-body
        class="overflow-hidden p-1"
        @mouseover="isHovered=true"
        @mouseleave="isHovered=false"
        @click="$emit('click')"
    >
      <!--          Line 1-->
      <div class="d-flex">

        <div
            class="d-flex"
            :style="'width: calc(98% - '+iconsWidth+')'"
        >
          <div v-if="isSelectable">
            <b-form-checkbox
                v-model="check"
            />
          </div>

          <div class="text-truncate">
            <b>{{ offer.title }}</b>

            <span class="text-secondary ml-50">
              <i>{{ offer.billNumber }}</i>
            </span>
          </div>
        </div>

        <div
            class="my-auto d-flex justify-content-between"
            :style="'width: '+iconsWidth+ ';'"
        >
          <icon
              v-if="offer.quotes.length > 0"
              icon="file-pdf"
              class="mx-500 cursor-pointer"
              :class="{'text-primary': isQuoteHover}"
              @mouseover.native="isQuoteHover=true"
              @mouseleave.native="isQuoteHover=false"

              @click="downloadByDocument(offer.quotes.slice(-1)[0])"
          />
          <div
              v-if="offer._isValidated"
              v-b-tooltip.hover.bottom="moment(offer.signedDocumentDate).format('LL')"
              @mouseover="isSignedDocumentHover=true"
              @mouseleave="isSignedDocumentHover=false"
          >
            <icon
                icon="file-signature"
                :disabled="offer.signedDocument==null"
                class="cursor-pointer"
                :class="{'text-primary': isSignedDocumentHover}"

                @click="downloadByDocument(offer.signedDocument)"
            />
          </div>
          <icon
              v-if="offer._linkedOutgoingInvoices.length > 0"
              icon="file-invoice-dollar"
              class="mx-500 cursor-pointer"
              :class="{'text-primary': isOutgoingInvoicesHover}"
              @mouseover.native="isOutgoingInvoicesHover=true"
              @mouseleave.native="isOutgoingInvoicesHover=false"

              @click="downloadFactures"
          />
        </div>
      </div>

      <!--          Line 2-->
      <div class="d-flex">
        <div class="w-100">
          <div class="d-flex mt-1">

            <div
                class="d-flex"
            >
              <b-avatar
                  v-if="offer._createdBy != null && offer._createdBy.avatar"
                  class="pull-up"
                  :src="offer._createdBy.avatar.url"
                  variant="light-primary"
                  size="sm"
              />
              <b-avatar
                  v-else
                  class="pull-up"
                  :text="offer._createdBy!=null?initials(offer._createdBy._display):'?'"
                  variant="light-primary"
                  size="sm"
              />

              <div class="ml-50 text-truncate">
                {{ offer._createdBy != null ? offer._createdBy._display : '?' }}
              </div>
            </div>

            <div class="d-flex text-nowrap ml-4">
              <icon icon="tags"/>

              <div class="ml-50">
                <b>{{ currency(offer.preTaxAmount) }} HT</b>
              </div>
            </div>

            <div
                v-if="isHistory && displayLastUpdate != null"
                class="d-flex text-secondary text-nowrap ml-4"
            >
              <icon icon="history"/>

              <div class="ml-50">
                <small>
                  <i>{{ displayLastUpdate }}</i>
                </small>
              </div>
            </div>


          </div>
        </div>

        <Transition name="slide">
          <div
              v-if="isHovered && (isEditable || isDeletable)"
              class="my-auto d-flex"
          >
            <view-button
                @click.native.stop="$emit('view')"
                :with-icon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Validate-->
            <validate-button
                v-if="offer._isValidated == false && offer.isDeclined != true"
                @click.native.stop="$emit('validate')"
                v-b-tooltip.hover.left="$t('validatedOffer')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Decline-->
            <decline-button
                v-if="offer._isValidated == false && offer.isDeclined != true && offer.isArchived == false"
                @click.native.stop="$emit('decline')"
                v-b-tooltip.hover.left="$t('DeclinedOffer')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--        Transform to outgoing invoice-->
            <to-invoice-button
                v-if="offer._isValidated && offer._status != 'Fully invoiced'"
                @click.native.stop="$emit('transformToOutgoingInvoice')"
                v-b-tooltip.hover.left="capitalize($t('transformOfferToOutgoingInvoice'))"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <edit-button
                @click.native.stop="$emit('edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :disabled="!isEditable || offer._isValidated"
                size="sm"
            />

            <!--          Archivate -->
            <archivate
                v-if="(offer._isValidated == true || offer.isDeclined == true) && offer.isArchived == false"
                @click.native.stop="$emit('archivate')"
                v-b-tooltip.hover.left="$t('ArchiveOffer')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          UnArchivate -->
            <unarchivate
                v-if="(offer._isValidated == true || offer.isDeclined == true) && offer.isArchived == true"
                @click.native.stop="$emit('unArchivate')"
                v-b-tooltip.hover.left="$t('UnArchiveOffer')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Duplicate-->
            <button-duplicate
                v-if="$can('manage', 'offers')"
                v-b-tooltip.hover.left="capitalize($t('duplicate'))"
                @click.native.stop="$emit('duplicate')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <remove-button
                @click.native.stop="$emit('delete')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :is-disabled="!isDeletable || offer._isValidated"
                size="sm"
            />
          </div>
        </Transition>
      </div>

    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch }         from '@vue/composition-api'
import ViewButton                       from '../button/View.vue'
import EditButton                       from '../button/Edit.vue'
import RemoveButton                     from '../button/Remove.vue'
import ValidateButton                   from '../button/Validate.vue'
import DeclineButton                    from '../button/Close.vue'
import ToInvoiceButton                  from '../button/ToInvoice2.vue'
import ArchivateButton                  from '../button/Archivate.vue'
import UnarchivateButton                from '../button/Unarchivate.vue'
import DuplicateButton                  from '../button/Duplicate.vue'
import { capitalize, currency }         from '../../utils/filter'
import { downloadByDocument, initials } from '../../utils/utils'
import { useCustomerInvoices }          from '../../views/sales/customerInvoice/useCustomerInvoice'
import ButtonClose                      from '../button/Close.vue'
import ButtonDownload                   from '../button/Download.vue'
import ButtonValidate                   from '../button/Validate.vue'
import ButtonToInvoice                  from '../button/ToInvoice2.vue'
import ButtonDuplicate                  from '../button/Duplicate.vue'
import ButtonEdit                       from '../button/Edit.vue'
import Unarchivate                      from '../button/Unarchivate.vue'
import Archivate                        from '../button/Archivate.vue'
import moment                           from 'moment'
import i18n                             from '../../libs/i18n'

export default {
  components: {
    Archivate, Unarchivate, ButtonEdit, ButtonDuplicate,
    ButtonToInvoice,
    ButtonValidate, ButtonDownload, ButtonClose,
    ViewButton,
    RemoveButton,
    EditButton,
    ValidateButton,
    DeclineButton,
    ToInvoiceButton,
    ArchivateButton,
    UnarchivateButton,
    DuplicateButton
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const check = ref(false)
    const isHovered = ref(false)

    const isQuoteHover = ref(false)
    const isSignedDocumentHover = ref(false)
    const isOutgoingInvoicesHover = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const iconsWidth = computed(() => {
      let output = 0
      let iconWidth = 22

      if (props.offer.quotes.length > 0) {
        output += iconWidth
      }
      if (props.offer._isValidated) {
        output += iconWidth
      }
      if (props.offer._linkedOutgoingInvoices.length > 0) {
        output += iconWidth
      }

      return output + 'px'
    })

    const lastUpdate = computed(() => {
      if (props.offer.logs.length == 0) {
        return null
      } else {
        return props.offer.logs.slice(-1)[0]
      }
    })

    const displayLastUpdate = computed(() => {
      if (lastUpdate == null || lastUpdate.value.action == 'create') {
        return null
      } else {
        return capitalize(i18n.t('lastUpdateDate', { 'date': moment(lastUpdate.value.at).fromNow() }))
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { downloadOutgoingInvoice } = useCustomerInvoices()
    const downloadFactures = () => {
      props.offer._linkedOutgoingInvoices.forEach(oi => {
        downloadOutgoingInvoice(oi)
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      moment,

      // Data
      check,
      isHovered,
      isQuoteHover,
      isSignedDocumentHover,
      isOutgoingInvoicesHover,

      // Computed
      iconsWidth,
      displayLastUpdate,

      // Methods
      downloadFactures,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize, downloadByDocument, initials, currency },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>