<template>
  <b-card
      no-body
      class="overflow-hidden cursor-default p-1"

      @mouseover="isCardHovered=true"
      @mouseleave="isCardHovered=false"
      @click="$emit('click')"
  >


    <div class="d-flex justify-content-between ">
      <div class="text-nowrap text-truncate my-auto w-100 mr-1">
        <b-avatar
            v-if="workFloCall._createdBy.avatar"
            class="pull-up"
            :src="workFloCall._createdBy.avatar.url"
            variant="light-primary"
            size="sm"
        />
        <b-avatar
            v-else
            class="pull-up"
            :text="getInitials(workFloCall._createdBy._display)"
            variant="light-primary"
            size="sm"
        />

        <span class="ml-1">
          <b>{{ workFloCall._createdBy._display }}</b>
        </span>
      </div>

      <div class="text-nowrap text-secondary my-auto">
        <small>
          <i>{{ capitalize(getFromNow(workFloCall._createdAt)) }}</i>
        </small>
      </div>


      <Transition name="slide">
        <div v-if="isCardHovered"
             class="text-nowrap my-auto ml-2">
          <icon
              icon="edit"
              class="mr-1 cursor-pointer"
              :class="{'text-warning': isEditHovered}"
              :disabled="isEditDisabled||workFloCall._createdBy.id != getUserData().id"

              @mouseover.native="isEditHovered=true"
              @mouseleave.native="isEditHovered=false"
              @click.native="$emit('editClick')"
          />

          <icon
              icon="trash-alt"
              class="mr-1 cursor-pointer"
              :class="{'text-danger': isTrashHovered}"
              :disabled="isTrashDisabled||workFloCall._createdBy.id != getUserData().id"

              @mouseover.native="isTrashHovered=true"
              @mouseleave.native="isTrashHovered=false"
              @click.native="$emit('trashClick')"
          />
        </div>
      </Transition>

    </div>

    <div class="d-flex justify-content-between mt-50">
      <div class="text-truncate text-nowrap my-auto">
        <span v-if="workFloCall.outgoingCall">
          {{ capitalize($t('outgoingCall')) }}
        </span>
        <span v-else>
          {{ capitalize($t('incomingCall')) }}
        </span>

        <span><i>({{duration}})</i></span>
      </div>
<!--      <span v-if="workFloCall.outgoingCall"-->
<!--            class="text-truncate text-nowrap my-auto">-->
<!--        {{ capitalize($t('outgoingCall')) }}-->
<!--      </span>-->
<!--      <span v-else-->
<!--            class="text-truncate text-nowrap my-auto">-->
<!--        {{ capitalize($t('incomingCall')) }}-->
<!--      </span>-->

<!--      <span>{{workFloCall.duration}}</span>-->
      <div
          v-if="workFloCall.contact"
           class="mt-50 text-nowrap my-auto text-center px-50"
      >
        <icon icon="user"/>
        <span class="ml-50">{{ workFloCall.contact._display }}</span>
      </div>

      <span class="text-truncate text-nowrap my-auto">
        <b-badge pill
                 :variant="'light-'+resultTypeColor">
          <i>{{ capitalize($t(workFloCall.resultType)) }}</i>
        </b-badge>
      </span>
    </div>

<!--    <div v-if="workFloCall.contact" class="mt-50">-->
<!--      <icon icon="user"/>-->
<!--      <span class="ml-50">{{ workFloCall.contact._display }}</span>-->
<!--    </div>-->

    <div v-if="workFloCall.note"
         v-html="workFloCall.note"
         class="px-1 pt-1 workFloCallTextArea"/>

  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'
import { getUserData } from '../../utils/utils'

import moment from 'moment'
import i18n from '../../libs/i18n'

export default {
  components: {},
  props: {
    workFloCall: {
      type: Object,
      default: () => {}
    },
    isEditDisabled: {
      type: Boolean,
      default: false
    },
    isTrashDisabled: {
      type: Boolean,
      default: false
    },
  },
  setup (props) {
    // console.log(JSON.parse(JSON.stringify(props.workFloCall)))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isCardHovered = ref(false)
    const isEditHovered = ref(false)
    const isTrashHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const resultTypeColor = computed(() => {
      if (props.workFloCall.resultType == 'successful call') {
        return 'success'
      } else if(['voice message', 'interrupted call', 'busy'].includes(props.workFloCall.resultType)) {
        return 'warning'
      } else {
        return 'danger'
      }
    })

    const duration = computed(() => {
      if (props.workFloCall.duration.length == 25) {
        return props.workFloCall.duration.slice(11, 19)
      } else {
        return props.workFloCall.duration
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    const getInitials = (display) => {
      let names = display.split(' '),
          initials = names[0].substring(0, 1).toUpperCase()

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    }

    const getFromNow = (date) => {
      return moment(date).fromNow()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      getUserData,

      // Data
      isCardHovered,
      isEditHovered,
      isTrashHovered,

      // Computed
      resultTypeColor,
      duration,

      // Methods
      getInitials,
      getFromNow,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.slide-leave-active,
.slide-enter-active {
  transition: .3s;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-to {
  transform: translateX(200%);
}

.workFloCallTextArea {
  p {
    margin-bottom: 0px;
  }
}
</style>