<template>
  <b-card
      no-body
      class="overflow-hidden cursor-default p-50"

      @mouseover="isCardHovered=true"
      @mouseleave="isCardHovered=false"
      @click="$emit('click')"
  >

    <div class="d-flex justify-content-between px-50">
      <div class="w-100">
        <div>
          <icon
              v-if="icon!=''"
              :icon="icon"
              class="mr-1"
          />
          <span class="text-nowrap text-truncate">
            <b>{{ workFloTask.title }}</b>
          </span>

          <icon
              v-if="workFloTask.linkedContact != null"
              icon="user"
              class="ml-3 mr-50"
          />
          <span v-if="workFloTask.linkedContact != null">{{ workFloTask.linkedContact._display }}</span>

        </div>
      </div>

      <div
          v-if="workFloTask.date"
          class="my-auto text-nowrap mr-1"
          :class="{'text-danger': isBeforeNow(workFloTask.date)}"
      >
        <small>{{ dateToFromNowDaily(workFloTask.date) }}</small>
      </div>

      <div
          class="my-auto"
          :class="{'mr-1':isCardHovered}"
      >
        <b-avatar-group size="sm">
          <div
              v-for="(usr, index) in workFloTask.assignedTo"
              :key="'workFloTaskCardAssignTo-'+index"
          >
            <b-avatar
                v-if="usr.avatar"
                class="pull-up"
                :src="usr.avatar.url"
                variant="light-primary"
            />
            <b-avatar
                v-else
                class="pull-up"
                :text="getInitials(usr._display)"
                variant="light-primary"
            />

          </div>

        </b-avatar-group>
      </div>


      <Transition name="slide">
        <div
            v-if="isCardHovered"
            class="text-nowrap"
        >
          <icon
              :icon="workFloTask.performed?'redo-alt':'check'"
              class="mr-1 cursor-pointer"
              :class="{'text-success': isCheckHovered}"
              :disabled="isCheckDisabled"

              @mouseover.native="isCheckHovered=true"
              @mouseleave.native="isCheckHovered=false"
              @click.native="$emit('checkClick')"
          />
          <icon
              icon="edit"
              class="mr-1 cursor-pointer"
              :class="{'text-warning': isEditHovered}"
              :disabled="isEditDisabled"

              @mouseover.native="isEditHovered=true"
              @mouseleave.native="isEditHovered=false"
              @click.native="$emit('editClick')"
          />
          <icon
              icon="calendar-plus"
              class="mr-1 cursor-pointer"
              :class="{'text-primary': isCalendarHovered}"
              :disabled="isCalendarDisabled"

              @mouseover.native="isCalendarHovered=true"
              @mouseleave.native="isCalendarHovered=false"
              @click.native="$emit('calendarClick')"
          />
          <icon
              icon="trash-alt"
              class="mr-1 cursor-pointer"
              :class="{'text-danger': isTrashHovered}"
              :disabled="isTrashDisabled"

              @mouseover.native="isTrashHovered=true"
              @mouseleave.native="isTrashHovered=false"
              @click.native="$emit('trashClick')"
          />
        </div>
      </Transition>
    </div>

  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../utils/filter'

import moment from 'moment'
import i18n from '../../libs/i18n'

export default {
  components: {},
  props: {
    workFloTask: {
      type: Object,
      default: () => {}
    },
    isCheckDisabled: {
      type: Boolean,
      default: false
    },
    isEditDisabled: {
      type: Boolean,
      default: false
    },
    isTrashDisabled: {
      type: Boolean,
      default: false
    },
    isCalendarDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isCardHovered = ref(false)
    const isCheckHovered = ref(false)
    const isEditHovered = ref(false)
    const isTrashHovered = ref(false)
    const isCalendarHovered = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const dateToFromNowDaily = (date) => {
      let fromNow = moment(date).fromNow()

      return moment(date).calendar(null, {
        // when the date is closer, specify custom values
        // lastWeek: '[Last] dddd',
        // lastDay: '[yesterday]',
        // sameDay: '[today]',
        lastDay: '[' + capitalize(i18n.t('yesterday')) + ']',
        sameDay: '[' + capitalize(i18n.t('today')) + ']',
        nextDay: '[' + capitalize(i18n.t('tomorrow')) + ']',
        // nextWeek: 'dddd',
        // when the date is further away, use from-now functionality
        sameElse: function () {
          return '[' + capitalize(fromNow) + ']'
        }
      })
    }

    const isBeforeNow = (date) => {
      let tmp = moment(moment(date).format('YYYY-MM-DD'))
      let now = moment(moment().format('YYYY-MM-DD'))

      return tmp < now
    }

    const getInitials = (display) => {
      let names = display.split(' '),
          initials = names[0].substring(0, 1).toUpperCase()

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    }

    const icon = computed(() => {
      if (props.workFloTask.type == 'call') {
        return 'phone'
      } else if (props.workFloTask.type == 'appointment') {
        return 'calendar-alt'
      } else if (props.workFloTask.type == 'email') {
        return 'envelope'
      } else if (props.workFloTask.type == 'milestone') {
        return 'map-marked-alt'
      } else {
        return 'check'
      }
    })

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isCardHovered,
      isCheckHovered,
      isEditHovered,
      isTrashHovered,
      isCalendarHovered,

      // Computed
      icon,

      // Methods
      dateToFromNowDaily,
      getInitials,
      isBeforeNow,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.slide-leave-active,
.slide-enter-active {
  transition: .3s;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-to {
  transform: translateX(200%);
}
</style>