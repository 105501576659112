<template>
  <div>
    <b-card
        no-body
        class="overflow-hidden p-1"
        @mouseover="isHovered=true"
        @mouseleave="isHovered=false"
        @click="$emit('click')"
    >
      <!--          Line 1-->
      <div class="d-flex">

        <div
            class="d-flex"
            :style="'width: calc(98% - '+iconsWidth+')'"
        >
          <div v-if="isSelectable">
            <b-form-checkbox
                v-model="check"
            />
          </div>

          <div class="text-truncate">
            <b>{{ outgoingInvoice.title }}</b>

            <span
                v-if="outgoingInvoice.billNumber != null"
                class="text-secondary ml-50"
            >
              <i>{{ outgoingInvoice.billNumber }}</i>
            </span>
            <span
                v-else
                class="text-secondary ml-50"
            >
              <i>{{ capitalize($t('draft')) }}</i>
            </span>
          </div>


        </div>

        <div
            class="my-auto d-flex justify-content-between"
            :style="'width: '+iconsWidth+ ';'"
        >
          <icon
              v-if="outgoingInvoice"
              icon="file-invoice-dollar"
              class="mx-500 cursor-pointer"
              :class="{'text-primary': isOutgoingInvoiceHover}"
              @mouseover.native="isOutgoingInvoiceHover=true"
              @mouseleave.native="isOutgoingInvoiceHover=false"

              @click="downloadOutgoingInvoice(outgoingInvoice)"
          />
        </div>
      </div>

      <!--          Line 2-->
      <div class="d-flex">
        <div class="w-100">
          <div class="d-flex mt-1">

            <div
                class="d-flex"
            >
              <b-avatar
                  v-if="outgoingInvoice._createdBy != null && outgoingInvoice._createdBy.avatar"
                  class="pull-up"
                  :src="outgoingInvoice._createdBy.avatar.url"
                  variant="light-primary"
                  size="sm"
              />
              <b-avatar
                  v-else
                  class="pull-up"
                  :text="outgoingInvoice._createdBy!=null?initials(outgoingInvoice._createdBy._display):'?'"
                  variant="light-primary"
                  size="sm"
              />

              <div class="ml-50 text-truncate">
                {{ outgoingInvoice._createdBy != null ? outgoingInvoice._createdBy._display : '?' }}
              </div>
            </div>

            <div class="d-flex text-nowrap ml-4">
              <icon icon="tags"/>

              <div class="ml-50">
                <b>{{ currency(outgoingInvoice.preTaxAmount) }} HT</b>
              </div>
            </div>

            <div
                v-if="isHistory && displayLastUpdate != null"
                class="d-flex text-secondary text-nowrap ml-4"
            >
              <icon icon="history"/>

              <div class="ml-50">
                <small>
                  <i>{{ displayLastUpdate }}</i>
                </small>
              </div>
            </div>


          </div>
        </div>

        <Transition name="slide">
          <div
              v-if="isHovered && (isEditable || isDeletable)"
              class="my-auto d-flex"
          >
            <!--          View-->
            <view-button
                @click.native.stop="$emit('view')"
                :with-icon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Validate-->
            <validate-button
                v-if="outgoingInvoice._isValidated == false && outgoingInvoice.isDeclined != true"
                @click.native.stop="$emit('validate')"
                v-b-tooltip.hover.left="$t('validatedOutgoingInvoice')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Edit-->
            <edit-button
                @click.native.stop="$emit('edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :disabled="!isEditable || outgoingInvoice._isValidated"
                size="sm"
            />

            <!--          Duplicate-->
            <button-duplicate
                v-if="$can('manage', 'outgoingInvoices')"
                v-b-tooltip.hover.left="capitalize($t('duplicate'))"
                @click.native.stop="$emit('duplicate')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Remove-->
            <remove-button
                @click.native.stop="$emit('delete')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :is-disabled="!isDeletable || outgoingInvoice._isValidated"
                size="sm"
            />
          </div>
        </Transition>
      </div>

    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch }                from '@vue/composition-api'
import ViewButton                              from '../button/View.vue'
import EditButton                              from '../button/Edit.vue'
import RemoveButton                            from '../button/Remove.vue'
import ValidateButton                          from '../button/Validate.vue'
import DeclineButton                           from '../button/Close.vue'
import ToInvoiceButton                         from '../button/ToInvoice2.vue'
import DuplicateButton                         from '../button/Duplicate.vue'
import { capitalize, currency }                from '../../utils/filter'
import { clone, downloadByDocument, initials } from '../../utils/utils'
import { useCustomerInvoices }                 from '../../views/sales/customerInvoice/useCustomerInvoice'
import ButtonClose                             from '../button/Close.vue'
import ButtonDownload                          from '../button/Download.vue'
import ButtonValidate                          from '../button/Validate.vue'
import ButtonToInvoice                         from '../button/ToInvoice2.vue'
import ButtonDuplicate                         from '../button/Duplicate.vue'
import ButtonEdit                              from '../button/Edit.vue'
import moment                                  from 'moment'
import i18n                                    from '../../libs/i18n'

export default {
  components: {
    ButtonEdit,
    ButtonDuplicate,
    ButtonToInvoice,
    ButtonValidate, ButtonDownload, ButtonClose,
    ViewButton,
    RemoveButton,
    EditButton,
    ValidateButton,
    DeclineButton,
    ToInvoiceButton,
    DuplicateButton
  },
  props: {
    outgoingInvoice: {
      type: Object,
      default: () => {}
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const check = ref(false)
    const isHovered = ref(false)
    const isOutgoingInvoiceHover = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const iconsWidth = computed(() => {
      let output = 0
      let iconWidth = 22

      if (props.outgoingInvoice != null) {
        output += iconWidth
      }

      return output + 'px'
    })

    const lastUpdate = computed(() => {
      if (props.outgoingInvoice.logs.length == 0) {
        return null
      } else {
        return props.outgoingInvoice.logs.slice(-1)[0]
      }
    })

    const displayLastUpdate = computed(() => {
      if (lastUpdate == null || lastUpdate.value.action == 'create') {
        return null
      } else {
        return capitalize(i18n.t('lastUpdateDate', { 'date': moment(lastUpdate.value.at).fromNow() }))
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { downloadOutgoingInvoice } = useCustomerInvoices()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      moment,

      // Data
      check,
      isHovered,
      isOutgoingInvoiceHover,

      // Computed
      iconsWidth,
      displayLastUpdate,

      // Methods
      downloadOutgoingInvoice,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize, downloadByDocument, initials, currency },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>