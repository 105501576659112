<template>
  <div>
    <b-card
        no-body
        class="overflow-hidden p-1"
        @mouseover="isHovered=true"
        @mouseleave="isHovered=false"
        @click="$emit('click')"
    >
      <!--          Line 1-->
      <div class="d-flex">

        <div
            class="d-flex"
            :style="'width: calc(98% - '+iconsWidth+')'"
        >
          <div v-if="isSelectable">
            <b-form-checkbox
                v-model="check"
            />
          </div>

          <div class="text-truncate">
            <b>{{ incomingInvoice.supplierCompany._display }}</b>

            <span class="text-secondary ml-50">
              <i>{{ incomingInvoice.billNumber }}</i>
            </span>
          </div>
        </div>

        <div
            class="my-auto d-flex justify-content-between"
            :style="'width: '+iconsWidth+ ';'"
        >
          <icon
              v-if="incomingInvoice.receipt != null"
              icon="file-pdf"
              class="mx-500 cursor-pointer"
              :class="{'text-primary': isReceiptHover}"
              @mouseover.native="isReceiptHover=true"
              @mouseleave.native="isReceiptHover=false"

              @click="downloadByDocument(incomingInvoice.receipt)"
          />
        </div>
      </div>

      <!--          Line 2-->
      <div class="d-flex">
        <div class="w-100">
          <div class="d-flex mt-1">

            <div
                class="d-flex"
            >
              <b-avatar
                  v-if="incomingInvoice._createdBy != null && incomingInvoice._createdBy.avatar"
                  class="pull-up"
                  :src="incomingInvoice._createdBy.avatar.url"
                  variant="light-primary"
                  size="sm"
              />
              <b-avatar
                  v-else
                  class="pull-up"
                  :text="incomingInvoice._createdBy!=null?initials(incomingInvoice._createdBy._display):'?'"
                  variant="light-primary"
                  size="sm"
              />

              <div class="ml-50 text-truncate">
                {{ incomingInvoice._createdBy != null ? incomingInvoice._createdBy._display : '?' }}
              </div>
            </div>

            <div class="d-flex text-nowrap ml-4">
              <icon icon="tags"/>

              <div class="ml-50">
                <b>{{ currency(incomingInvoice.preTaxAmount) }} HT</b>
              </div>
            </div>

            <div
                v-if="isHistory && displayLastUpdate != null"
                class="d-flex text-secondary text-nowrap ml-4"
            >
              <icon icon="history"/>

              <div class="ml-50">
                <small>
                  <i>{{ displayLastUpdate }}</i>
                </small>
              </div>
            </div>


          </div>
        </div>

        <Transition name="slide">
          <div
              v-if="isHovered && (isEditable || isDeletable)"
              class="my-auto d-flex"
          >
            <!--          View -->
            <view-button
                @click.native.stop="$emit('view')"
                :with-icon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Edit -->
            <edit-button
                @click.native.stop="$emit('edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :disabled="!isEditable || incomingInvoice._isValidated"
                size="sm"
            />

            <!--          Remove -->
            <remove-button
                @click.native.stop="$emit('delete')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :is-disabled="!isDeletable || incomingInvoice._isValidated"
                size="sm"
            />
          </div>
        </Transition>
      </div>

    </b-card>
  </div>
</template>

<script>
import { ref, computed, watch }         from '@vue/composition-api'
import ViewButton                       from '../button/View.vue'
import EditButton                       from '../button/Edit.vue'
import RemoveButton                     from '../button/Remove.vue'
import { capitalize, currency }         from '../../utils/filter'
import { downloadByDocument, initials } from '../../utils/utils'
import { useCustomerInvoices }          from '../../views/sales/customerInvoice/useCustomerInvoice'
import moment                           from 'moment'
import i18n                             from '../../libs/i18n'

export default {
  components: {
    ViewButton,
    RemoveButton,
    EditButton,
  },
  props: {
    incomingInvoice: {
      type: Object,
      default: () => {}
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const check = ref(false)
    const isHovered = ref(false)

    const isReceiptHover = ref(false)
    const isSignedDocumentHover = ref(false)
    const isOutgoingInvoicesHover = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const iconsWidth = computed(() => {
      let output = 0
      let iconWidth = 22

      if (props.incomingInvoice.receipt != null) {
        output += iconWidth
      }

      return output + 'px'
    })

    const lastUpdate = computed(() => {
      if (props.incomingInvoice.logs.length == 0) {
        return null
      } else {
        return props.incomingInvoice.logs.slice(-1)[0]
      }
    })

    const displayLastUpdate = computed(() => {
      if (lastUpdate == null || lastUpdate.value.action == 'create') {
        return null
      } else {
        return capitalize(i18n.t('lastUpdateDate', { 'date': moment(lastUpdate.value.at).fromNow() }))
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { downloadOutgoingInvoice } = useCustomerInvoices()
    const downloadFactures = () => {
      props.incomingInvoice._linkedOutgoingInvoices.forEach(oi => {
        downloadOutgoingInvoice(oi)
      })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      moment,

      // Data
      check,
      isHovered,
      isReceiptHover,
      isSignedDocumentHover,
      isOutgoingInvoicesHover,

      // Computed
      iconsWidth,
      displayLastUpdate,

      // Methods
      downloadFactures,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { capitalize, downloadByDocument, initials, currency },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>