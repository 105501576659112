<template>
  <div>
    <b-tabs
        v-model="tabIndex">

      <b-tab
          :title="capitalize($tc('order', ordersContext.total_results)) + ' ('+ordersContext.total_results+')'"
      >
        <orders-view3
            v-if="isModuleViewsReady"
            @updatePayload="updateReload('orders', $event)"
        />
      </b-tab>

      <b-tab
          :title="capitalize($tc('expense', incomingInvoicesContext.total_results)) + ' ('+incomingInvoicesContext.total_results+')'"
      >
        <suppliers-invoices-view3
            v-if="isModuleViewsReady"
            ref="incomingInvoicesRef"
            @updatePayload="updateReload('supplierInvoices', $event)"
        />
      </b-tab>

      <b-tab
          :title="capitalize($tc('quote', offersContext.total_results)) + ' ('+offersContext.total_results+')'"
      >
        <offers-view3
            v-if="isModuleViewsReady"
            ref="offersRef"
            @updatePayload="updateReload('offers', $event)"
        />
      </b-tab>

      <b-tab
          :title="capitalize($tc('invoice', outgoingInvoicesContext.total_results)) + ' ('+outgoingInvoicesContext.total_results+')'"
      >
        <customer-invoices-view3
            v-if="isModuleViewsReady"
            ref="outgoingInvoicesRef"
            @updatePayload="updateReload('customerInvoices', $event)"
        />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../utils/filter'
import { getRoute }             from '../../../utils/utils'

import SuppliersInvoicesView3 from '../../../components/views/SuppliersInvoicesView3.vue'
import CustomerInvoicesView3  from '../../../components/views/CustomerInvoicesView3.vue'
import OffersView3            from '../../../components/views/OffersView3.vue'
import OrdersView3            from '../../../components/views/OrdersView3.vue'
import store                  from '../../../store'
import useAPI                 from '../../../utils/useAPI'
import router                 from '../../../router'

export default {
  components: {
    SuppliersInvoicesView3,
    CustomerInvoicesView3,
    OffersView3,
    OrdersView3
  },
  props: {
    // workFlo: {
    //   type: Object,
    //   default: () => {}
    // },
    isModuleViewsReady: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentSupplierInvoicesPayload = ref('{}')
    const currentOffersPayload = ref('{}')
    const currentCustomerInvoicesPayload = ref('{}')
    const currentOrdersPayload = ref('{}')
    const tabIndex = ref(0)
    const offersRef = ref()
    const outgoingInvoicesRef = ref()
    const incomingInvoicesRef = ref()

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      incomingInvoicesContext,
      offersContext,
      outgoingInvoicesContext,
      ordersContext
    } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateReload = (type, payload) => {
      if ('filters' in payload) {
        if (payload.filters.length == 0) {

          payload.filters.push({
            sectionLogicOperator: 'AND',
            sectionFilters: [
              {
                logicOperator: 'AND',
                field: 'workFlo',
                operator: 'equal',
                value: getRoute().params.workFloId
              },
            ]
          })
        } else {
          let index = payload.filters[0].sectionFilters.findIndex(g => g.field == 'workFlo')

          if (index == -1) {
            payload.filters[0].sectionFilters.push({
              logicOperator: 'AND',
              field: 'workFlo',
              operator: 'equal',
              value: getRoute().params.workFloId
            })
          } else {
            payload.filters[0].sectionFilters[index] = {
              logicOperator: 'AND',
              field: 'workFlo',
              operator: 'equal',
              value: getRoute().params.workFloId
            }
          }
        }
      } else {
        payload.filters = [{
          sectionLogicOperator: 'AND',
          sectionFilters: [
            {
              logicOperator: 'AND',
              field: 'workFlo',
              operator: 'equal',
              value: getRoute().params.workFloId
            },
          ]
        }]
      }

      if (type == 'supplierInvoices') {
        if (JSON.stringify(payload) != currentSupplierInvoicesPayload.value) {
          currentSupplierInvoicesPayload.value = JSON.stringify(payload)
          store.dispatch('incomingInvoice2/getIncomingInvoices', payload)
        }
      } else if (type == 'offers') {
        if (JSON.stringify(payload) != currentOffersPayload.value) {
          currentOffersPayload.value = JSON.stringify(payload)
          store.dispatch('offer2/getOffers', payload)
        }
      } else if (type == 'customerInvoices') {
        if (JSON.stringify(payload) != currentCustomerInvoicesPayload.value) {
          currentCustomerInvoicesPayload.value = JSON.stringify(payload)
          store.dispatch('outgoingInvoice2/getOutgoingInvoices', payload)
        }
      } else if (type == 'orders') {
        if (JSON.stringify(payload) != currentOrdersPayload.value) {
          currentOrdersPayload.value = JSON.stringify(payload)
          store.dispatch('order2/getOrders', payload)
        }
      }

    }

    const offerFunctionCalled = (entity, functionCalled) => {
      // console.log(entity, functionCalled)

      if (functionCalled == "view") {
        tabIndex.value= 2
        offersRef.value.showDetails(entity)
      } else if(functionCalled == "edit") {
        offersRef.value.editOfferLocal(entity)
      } else if(functionCalled == "validate") {
        offersRef.value.validateOfferLocal(entity)
      } else if(functionCalled == "transformToOutgoingInvoice") {
        offersRef.value.transformProductsToCustomerInvoiceLocal(entity)
      } else if(functionCalled == "archivate") {
        offersRef.value.archiveOfferLocal(entity, true)
      } else if(functionCalled == "unArchivate") {
        offersRef.value.archiveOfferLocal(entity, false)
      } else if(functionCalled == "duplicate") {
        offersRef.value.duplicateOffer(entity)
      } else if(functionCalled == "delete") {
        offersRef.value.deleteOfferAlert(entity)
      }
    }

    const outgoingInvoiceFunctionCalled = (entity, functionCalled) => {
      // console.log(entity, functionCalled)

      if (functionCalled == "view") {
        tabIndex.value= 3
        outgoingInvoicesRef.value.showDetails(entity)
      } else if(functionCalled == "edit") {
        outgoingInvoicesRef.value.editCustomerInvoiceLocal(entity)
      } else if(functionCalled == "validate") {
        outgoingInvoicesRef.value.validateCustomerInvoiceLocal(entity)
      } else if(functionCalled == "duplicate") {
        outgoingInvoicesRef.value.duplicateCustomerInvoice(entity)
      } else if(functionCalled == "delete") {
        outgoingInvoicesRef.value.deleteCustomerInvoiceAlert(entity)
      }
    }

    const incomingInvoiceFunctionCalled = (entity, functionCalled) => {
      // console.log(entity, functionCalled)

      if (functionCalled == "view") {
        tabIndex.value= 1
        incomingInvoicesRef.value.showDetails(entity)
      } else if(functionCalled == "edit") {
        incomingInvoicesRef.value.editSupplierInvoiceLocal(entity)
      } else if(functionCalled == "delete") {
        incomingInvoicesRef.value.deleteSupplierInvoiceAlert(entity)
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      tabIndex,
      offersRef,
      outgoingInvoicesRef,
      incomingInvoicesRef,

      // Computed
      incomingInvoicesContext,
      offersContext,
      outgoingInvoicesContext,
      ordersContext,

      // Methods
      updateReload,
      offerFunctionCalled,
      outgoingInvoiceFunctionCalled,
      incomingInvoiceFunctionCalled,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>