<template>
  <div>

    <work-flo-note-card
        v-for="(note, index) in workFloLocal.workFloNotes"
        :key="'workFloNote-'+index"
        :work-flo-note="note"

        @editClick="editWorkFloNote(note)"
        @trashClick="deleteWorkFloNoteAlert(note)"
    />


    <right-bottom-popup-note
        :is-open.sync="isNoteFormVisible"
        :note.sync="currentWorkFloNote"

        @submitValidated="submitValidatedWorkFloNoteLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

// import RightBottomPopup from '../../../components/rightBottomPopup/RightBottomPopup.vue'
import RightBottomPopupNote from '../../../components/rightBottomPopup/RightBottomPopupNote.vue'
import WorkFloNoteCard from '../../../components/card/WorkFloNoteCard.vue'

import store from '../../../store'
import moment from 'moment'
import { capitalize } from '../../../utils/filter'

export default {
  components: {
    // RightBottomPopup,
    RightBottomPopupNote,
    WorkFloNoteCard,
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isNoteFormVisible = ref(false)
    const currentWorkFloNote = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isNoteFormVisible, val => {
      if (val === false) {
        resetCurrentWorkFloNote()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const openNoteForm = () => {
      isNoteFormVisible.value = true
    }

    const resetCurrentWorkFloNote = () => {
      currentWorkFloNote.value = JSON.parse(JSON.stringify(store.getters['workFlo/getEmptyWorkFloNote']))
    }

    const submitValidatedWorkFloNoteLocal = () => {
      if ('id' in currentWorkFloNote.value) {
        let index = workFloLocal.value.workFloNotes.findIndex(i => i.id === currentWorkFloNote.value.id)
        workFloLocal.value.workFloNotes.splice(index, 1, JSON.parse(JSON.stringify(currentWorkFloNote.value)))
      } else {
        currentWorkFloNote.value._createdAt = moment().toJSON()
        workFloLocal.value.workFloNotes.unshift(JSON.parse(JSON.stringify(currentWorkFloNote.value)))
      }

      emit('updateWorkFlo')

      isNoteFormVisible.value = false
    }

    const editWorkFloNote = (workFloNote) => {
      currentWorkFloNote.value = JSON.parse(JSON.stringify(workFloNote))
      isNoteFormVisible.value = true
    }

    const removeWorkFloNote = (workFloNote) => {
      let index = workFloLocal.value.workFloNotes.findIndex(i => i.id === workFloNote.id)
      workFloLocal.value.workFloNotes.splice(index, 1)

      emit('updateWorkFlo')
    }


    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetCurrentWorkFloNote()

    return {
      // Components
      capitalize,

      // Data
      isNoteFormVisible,
      currentWorkFloNote,

      // Computed
      workFloLocal,
      // orderedNotes,

      // Methods
      openNoteForm,
      submitValidatedWorkFloNoteLocal,
      editWorkFloNote,
      removeWorkFloNote,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteWorkFloNoteAlert (workFloNote) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theWorkFloNote') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeWorkFloNote(workFloNote)
            }
          })
    }
    },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.ql-editor {
  overflow-y: scroll;
  min-height: 200px;
}

.b-toaster.b-toaster-bottom-right .b-toaster-slot {
  max-width: 1000px;
}

.b-toast {
  max-width: 1000px!important;
}
</style>