<template>
  <div>

    <work-flo-header
        :work-flo.sync="workFlo"
        :is-mounted="isMounted"
        class="mb-2"

        @newTask="newTask"
        @newNote="newNote"
        @newCall="newCall"
        @newEvent="newEvent"
        @newIntervention="newIntervention"
        @editWorkFlo="editWorkFlo"
        @updateWorkFlo="submitValidatedWorkFloLocal"
    />

    <div class="d-flex justify-content-center">

      <b-card
          :border-variant="((activitySelected||activityHover) && !generalInformationHover && !relatedDocumentHover)?'primary':''"
          bg-variant="transparent"
          class="text-center cursor-pointer mx-1"
          :class="{'text-primary': activitySelected||activityHover}"
          @mouseover="activityHover = true"
          @mouseleave="activityHover = false"
          @click="selectMainTab('activity')"
      >
        <icon
            icon="compass"
            :size="['xs','sm'].includes(currentBreakPoint)?'2x':'2x'"
        />
        <div
            v-if="!['xs','sm'].includes(currentBreakPoint)"
            class="mt-50"
        >{{ capitalize($tc('activity', 2)) }}
        </div>
      </b-card>

      <b-card
          :border-variant="((generalInformationSelected||generalInformationHover) && !activityHover && !relatedDocumentHover)?'primary':''"
          bg-variant="transparent"
          class="text-center cursor-pointer mx-1"
          :class="{'text-primary': generalInformationSelected||generalInformationHover}"
          @mouseover="generalInformationHover = true"
          @mouseleave="generalInformationHover = false"
          @click="selectMainTab('generalInformation')"
      >
        <icon
            icon="info-circle"
            :size="['xs','sm'].includes(currentBreakPoint)?'2x':'2x'"
        />
        <div
            v-if="!['xs','sm'].includes(currentBreakPoint)"
            class="mt-50"
        >{{ capitalize($tc('generalInformation', 2)) }}
        </div>
      </b-card>

      <b-card
          :border-variant="((relatedDocumentSelected||relatedDocumentHover) && !activityHover && !generalInformationHover)?'primary':''"
          bg-variant="transparent"
          class="text-center cursor-pointer mx-1"
          :class="{'text-primary': relatedDocumentSelected||relatedDocumentHover}"
          @mouseover="relatedDocumentHover = true"
          @mouseleave="relatedDocumentHover = false"
          @click="selectMainTab('relatedDocument')"
      >
        <icon
            icon="folder-open"
            :size="['xs','sm'].includes(currentBreakPoint)?'2x':'2x'"
        />
        <div
            v-if="!['xs','sm'].includes(currentBreakPoint)"
            class="mt-50"
        >{{ capitalize($tc('relatedDocument', 2)) }}
        </div>
      </b-card>
    </div>

    <activity-tab
        v-if="isMounted"
        v-show="activitySelected"
        ref="activityTabRef"
        :work-flo.sync="workFlo"

        @updateWorkFlo="submitValidatedWorkFloLocal"
        @offerFunctionCalled="offerFunctionCalled"
        @outgoingInvoiceFunctionCalled="outgoingInvoiceFunctionCalled"
        @incomingInvoiceFunctionCalled="incomingInvoiceFunctionCalled"
    />

    <general-information-tab
        v-if="generalInformationSelected"
        :work-flo="workFlo"
    />

    <related-document-tab
        ref="relatedDocumentsTabRef"
        v-show="relatedDocumentSelected"
        :is-module-views-ready="isModuleViewsReady"
    />

    <!-- modal workFlo -->
    <modal-work-flo
        :work-flo.sync="editedWorkFlo"
        :title="('id' in workFlo)?$t('EditWorkFlo'):$t('NewWorkFlo')"
        :isOpen.sync="workFloModalShow"
        @submitValidated="submitValidatedWorkFloLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch, onUnmounted } from '@vue/composition-api'
import { useMS }                             from '../../utils/useMS'
import { capitalize }                        from '../../utils/filter'
import { useWorkFlos }                       from './useWorkFlos'

import WorkFloHeader         from './components/WorkFloHeader.vue'
import ModalWorkFlo          from '../../components/prompt/WorkFlo.vue'
import ActivityTab           from './components/ActivityTab.vue'
import GeneralInformationTab from './components/GeneralInformationTab.vue'
import RelatedDocumentTab    from './components/RelatedDocumentTab.vue'

import store           from '../../store'
import router          from '../../router'
import useAPI          from '../../utils/useAPI'
import { getUserData } from '../../utils/utils'
import { useEmails }   from '../../components/email/v2/useEmails'
import ability         from '../../libs/acl/ability'

export default {
  components: {
    WorkFloHeader,
    ModalWorkFlo,
    ActivityTab,
    GeneralInformationTab,
    RelatedDocumentTab
  },
  props: {},
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const workFlo = ref({
      _warnings: [],
      _calendarEvents: [],
      company: {},

      assignment: getUserData() ? {
        id: getUserData().id,
        _display: getUserData()._display
      } : {},
      events: [],
      interventions: [],
      isProspecting: false,
      typeOfPurchase: 'Premier achat',
      typeOfSale: 'Vente directe',
      typeOfApplication: 'Normal',
      state: 'in progress',
      status: {
        id: 1,
        name: 'Prospect'
      },
      source: 'reference',
      workFloStatusDates: [],
      workFloNeeds: [],
      workFloCalls: [],
      _history: {
        comingSoon: {
          workFloCalls: []
        },
        history: []
      }
    })
    const isMounted = ref(false)
    const workFloModalShow = ref(false)
    const activityTabRef = ref()

    const activitySelected = ref(true)
    const activityHover = ref(false)
    const generalInformationSelected = ref(false)
    const generalInformationHover = ref(false)
    const relatedDocumentSelected = ref(false)
    const relatedDocumentHover = ref(false)
    const editedWorkFlo = ref({})
    const isModuleViewsReady = ref(false)

    const relatedDocumentsTabRef = ref()

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloLocal = computed(() => {
      // if (isMounted.value == true) {
      //   console.log(router.history.current.params.workFloId)
      //   console.log(store.getters['workFlo/getWorkFlo'](router.history.current.params.workFloId))
      //
      //   return store.getters['workFlo/getWorkFlo'](router.history.current.params.workFloId)
      // } else {
      return workFlo.value
      // }
      // console.log(router.history.current.params.workFloId)
      // console.log(store.getters['workFlo/getWorkFlo'](router.history.current.params.workFloId))
      // return store.getters['workFlo/getWorkFlo'](router.history.current.params.workFloId)
    })

    const currentBreakPoint = computed(() => {
      return store.getters['app/currentBreakPoint']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(() => workFlo, val => {
    //   if (isMounted.value) {
    //     console.log('WorkFlo updated : ', val.value)
    //     submitValidatedWorkFlo(val.value)
    //   }
    // }, { deep: true })

    watch(workFloLocal, val => {
      if (isMounted.value == true) {
        // console.log('1111111111111111')
        // console.log(val)
        // console.log(JSON.parse(JSON.stringify(val)))
        workFlo.value = val
      }
    })

    watch(workFloModalShow, val => {
      if (val == false) {
        // editedWorkFlo.value = {}
        editedWorkFlo.value = {}
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchAutocompleteWorkFloStatuss,
      fetchAutocompleteWorkFloUsages,
      fetchAutocompleteCompanies,
      fetchAutocompleteEmployees,
      fetchAutocompleteUsers,
      fetchAutocompleteContacts,
      fetchAutocompletePaymentMethods,
      fetchAutocompleteBankAccounts,
      fetchAutocompleteArticles,
      fetchAutocompleteVats,
      fetchAutocompletePaymentTerms,
      fetchAutocompleteWorkFlos,
      fetchAutocompleteWorkflows,
      fetchWorkFloHistory
    } = useAPI()

    const { refreshEmails } = useEmails()

    const { submitValidatedWorkFlo } = useWorkFlos()

    const { selectAccount } = useMS()

    const submitValidatedWorkFloLocal = () => {
      // console.log('A')
      if (workFloModalShow.value == true) {
        // console.log('B')
        submitValidatedWorkFlo(editedWorkFlo.value)
            .then(response => {
              workFlo.value = response.data
              workFloModalShow.value = false
            })
      } else {
        // console.log('C')
        // console.log(JSON.parse(JSON.stringify(workFlo.value)))
        submitValidatedWorkFlo(workFlo.value)
            .then(response => {
            })
      }

    }

    const selectMainTab = (mainTab) => {
      activitySelected.value = false
      generalInformationSelected.value = false
      relatedDocumentSelected.value = false

      if (mainTab == 'activity') {
        activitySelected.value = true
      } else if (mainTab == 'generalInformation') {
        generalInformationSelected.value = true
      } else {
        relatedDocumentSelected.value = true
      }
    }

    const editWorkFlo = () => {
      editedWorkFlo.value = JSON.parse(JSON.stringify(store.getters['workFlo/getCurrentWorkFlo']))
      // console.log(editedWorkFlo.value)
      workFloModalShow.value = true
    }

    const newTask = () => {
      activityTabRef.value.addNewTask()
    }

    const newNote = () => {
      activityTabRef.value.addNewNote()
    }

    const newCall = () => {
      activityTabRef.value.addNewCall()
    }

    const newEvent = () => {
      activityTabRef.value.addNewEvent()
    }

    const newIntervention = () => {
      activityTabRef.value.addNewIntervention()
    }

    const offerFunctionCalled = ({ entity, functionCalled }) => {
      // console.log(entity, functionCalled)
      if (functionCalled == 'view') {
        activitySelected.value = false
        relatedDocumentSelected.value = true
      }

      relatedDocumentsTabRef.value.offerFunctionCalled(entity, functionCalled)
    }

    const outgoingInvoiceFunctionCalled = ({ entity, functionCalled }) => {
      // console.log(entity, functionCalled)
      if (functionCalled == 'view') {
        activitySelected.value = false
        relatedDocumentSelected.value = true
      }

      relatedDocumentsTabRef.value.outgoingInvoiceFunctionCalled(entity, functionCalled)
    }

    const incomingInvoiceFunctionCalled = ({ entity, functionCalled }) => {
      // console.log(entity, functionCalled)
      if (functionCalled == 'view') {
        activitySelected.value = false
        relatedDocumentSelected.value = true
      }

      relatedDocumentsTabRef.value.incomingInvoiceFunctionCalled(entity, functionCalled)
    }

    // const workFloNoteFunctionCalled = ({ entity, functionCalled }) => {
    //   console.log(entity, functionCalled)
    //       //
    //       // if (functionCalled == 'edit') {
    //       //
    //       // } else if(functionCalled == 'delete') {
    //       //
    //       // }
    //   activityTabRef.value.workFloNoteFunctionCalled(entity, functionCalled)
    // }

    const refreshEmailLocal = () => {
      refreshEmails()
          .then(response => {
            // console.log(response)
            if (response.newEmails.length > 0) {
              activityTabRef.value.activityTabEmailsRef.readMailsLocal
            }
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    // if (getUserData().roles.includes('ROLE_ADMIN')) {
      if (ability.can('manage', "Emails")) {
      onUnmounted(() => {
        clearInterval(interval)
      })
    }

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    let interval = null
    selectAccount()
    store.dispatch('moduleView/getModuleViews')
        .then(() => {
          store.dispatch('workFlo/getWorkFlo', router.history.current.params.workFloId)
              .then(response => {
                // console.log(response)
                workFlo.value = response.data
                setTimeout(() => {
                  isMounted.value = true

                  setTimeout(() => {
                    // activityTabRef.value.$refs.activityTabEmailsRef.readMailsLocal('Inbox')
                  }, 100)

                }, 100)

                fetchWorkFloHistory(router.history.current.params.workFloId)
                // store.dispatch('workFlo/getWorkFloHistory', )
                //     .then(response => {
                //       // history.value = response.data
                //     })

                fetchAutocompleteWorkFloStatuss()
                fetchAutocompleteWorkFloUsages()
                fetchAutocompleteCompanies()
                fetchAutocompleteEmployees()
                fetchAutocompleteUsers()
                fetchAutocompleteContacts()
                fetchAutocompletePaymentMethods()
                fetchAutocompleteBankAccounts()
                fetchAutocompleteArticles()
                fetchAutocompleteVats()
                fetchAutocompletePaymentTerms()
                fetchAutocompleteWorkFlos()
                fetchAutocompleteWorkflows()

                // if (getUserData().roles.includes('ROLE_ADMIN')) {
                if (ability.can('manage', 'Emails')) {
                  refreshEmailLocal()
                  interval = setInterval(() => {
                    if (store.getters['app/emailLoaders'] == 0) {
                      refreshEmailLocal()
                    }
                  }, 2 * 60000)
                }

                isModuleViewsReady.value = true
              })
        })

    return {
      // Components
      capitalize,

      // Data
      workFlo,
      isMounted,
      workFloModalShow,
      activityTabRef,
      activitySelected,
      activityHover,
      generalInformationSelected,
      generalInformationHover,
      relatedDocumentSelected,
      relatedDocumentHover,
      editedWorkFlo,
      isModuleViewsReady,
      relatedDocumentsTabRef,

      // Computed
      currentBreakPoint,

      // Methods
      submitValidatedWorkFloLocal,
      selectMainTab,
      editWorkFlo,
      newTask,
      newNote,
      newCall,
      newEvent,
      newIntervention,
      offerFunctionCalled,
      outgoingInvoiceFunctionCalled,
      incomingInvoiceFunctionCalled,
      // workFloNoteFunctionCalled,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    lang="scss"
    scoped
>
.bottomRightModal {
  width: 20%;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0;
}

.card-body {
  padding: 1rem 1.5rem;
}
</style>