<template>
  <div>

    <work-flo-call-card
        v-for="(call, index) in workFloLocal.workFloCalls"
        :key="'workFloCall-'+index"
        :work-flo-call="call"

        @editClick="editWorkFloCall(call)"
        @trashClick="removeWorkFloCall(call)"
    />


    <work-flo-call-prompt
        :is-open.sync="isWorkFloCallPromptOpen"
        :work-flo-call.sync="currentWorkFloCall"
        :work-flo="workFlo"
        :title="('id' in currentWorkFloCall)?$t('EditWorkFloCall'):$t('NewWorkFloCall')"

        @submitValidated="submitValidatedWorkFloCallLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import store from '../../../store'

import WorkFloCallPrompt from '../../../components/prompt/WorkFloCallPrompt.vue'
import WorkFloCallCard from '../../../components/card/WorkFloCallCard.vue'

export default {
  components: {
    WorkFloCallPrompt,
    WorkFloCallCard,
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isWorkFloCallPromptOpen = ref(false)
    const currentWorkFloCall = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isWorkFloCallPromptOpen, val => {
      if (val === false) {
        resetCurrentWorkFloCall()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const openPrompt = () => {
      isWorkFloCallPromptOpen.value = true
    }

    const resetCurrentWorkFloCall = () => {
      currentWorkFloCall.value = JSON.parse(JSON.stringify(store.getters['workFlo/getEmptyWorkFloCall']))
    }

    const submitValidatedWorkFloCallLocal = () => {
      if ('id' in currentWorkFloCall.value) {
        let index = workFloLocal.value.workFloCalls.findIndex(i => i.id === currentWorkFloCall.value.id)
        workFloLocal.value.workFloCalls.splice(index, 1, JSON.parse(JSON.stringify(currentWorkFloCall.value)))
      } else {
        workFloLocal.value.workFloCalls.push(JSON.parse(JSON.stringify(currentWorkFloCall.value)))
      }

      emit('updateWorkFlo')

      isWorkFloCallPromptOpen.value = false
    }

    const editWorkFloCall = (workFloCall) => {
      currentWorkFloCall.value = JSON.parse(JSON.stringify(workFloCall))
      isWorkFloCallPromptOpen.value = true
    }

    const removeWorkFloCall = (workFloCall) => {
      let index = workFloLocal.value.workFloCalls.findIndex(i => i.id === workFloCall.id)
      workFloLocal.value.workFloCalls.splice(index, 1)

      emit('updateWorkFlo')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetCurrentWorkFloCall()

    return {
      // Components

      // Data
      isWorkFloCallPromptOpen,
      currentWorkFloCall,

      // Computed
      workFloLocal,

      // Methods
      openPrompt,
      submitValidatedWorkFloCallLocal,
      editWorkFloCall,
      removeWorkFloCall,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>