<template>
  <div>
<!--    <events-calendar-->
<!--        ref="eventsCalendar"-->
<!--        :mandatory-filter="mandatoryFilter"-->
<!--    />-->
    <events-calendar
        ref="eventsCalendarRef"

        @addReminder="addReminderLocal($event)"
        @editReminder="editReminderLocal($event)"
        @duplicateReminder="duplicateReminderLocal($event)"
        @removeReminder="removeReminderLocal($event)"
    />

    <reminder
        class="d-none"
        ref="reminderRef"
        :reminders="{}"

        @updateCalendar="updateCalendarLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import EventsCalendar           from '../../../components/eventsCalendar/EventsCalendar.vue'
import store                    from '../../../store'
import { getUserData }          from '../../../utils/utils'
import Reminder                 from '../../../components/reminder/Reminder.vue'

export default {
  components: { Reminder, EventsCalendar },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // console.log(props.workFlo)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const eventsCalendarRef = ref()
    const reminderRef = ref()
    const mandatoryFilter = ref([{
      sectionLogicOperator: 'AND',
      sectionFilters: [
        {
          logicOperator: 'AND',
          field: 'workFlo',
          operator: 'equal',
          value: props.workFlo.id
        },
      ]
    }])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const addEvent = () => {
      eventsCalendarRef.value.addEvent()
    }

    const addReminderLocal = reminder => {
      reminderRef.value.addExternalReminder(reminder)
    }

    const editReminderLocal = reminder => {
      reminderRef.value.editExternalReminder(reminder)
    }

    const duplicateReminderLocal = reminder => {
      reminderRef.value.duplicateReminder(reminder)
    }

    const removeReminderLocal = reminder => {
      reminderRef.value.removeReminderAlert(reminder)
    }

    const updateCalendarLocal = () => {
      eventsCalendarRef.value.reFetchEvents()
    }

    // const updateReminders = () => {
    //   store.dispatch('user2/getUserReminders', getUserData().id)
    //       .then(response => {
    //         // console.log(response)
    //         reminders.value = response.data
    //       })
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      eventsCalendarRef,
      reminderRef,
      mandatoryFilter,

      // Computed

      // Methods
      addEvent,
      addReminderLocal,
      editReminderLocal,
      duplicateReminderLocal,
      removeReminderLocal,
      updateCalendarLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>