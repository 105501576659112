<template>
  <div>

    <b-tabs fill>

      <b-tab>
        <template #title>
          <icon icon="calendar-alt"/>
          <span class="ml-1">{{ capitalize($tc('calendar')) }}</span>
        </template>

        <!--        <activity-tab-calendar-->
        <!--            :work-flo="workFloLocal"-->

        <!--            @updateTask="activityTabTasksRef.editWorkFloTask($event)"-->
        <!--            @updateCall="activityTabCallsRef.editWorkFloCall($event)"-->
        <!--            @updateEvent="activityTabEventsRef.editWorkFloEvent($event)"-->
        <!--            @updateIntervention="activityTabInterventionsRef.editWorkFloIntervention($event)"-->
        <!--        />-->

        <activity-tab-calendar2
            ref="activityTabCalendar2"
            :work-flo="workFloLocal"
        />
      </b-tab>


      <b-tab active>
        <template #title>
          <icon icon="stream"/>
          <span class="ml-1">{{ capitalize($t('history')) }}</span>
        </template>

        <activity-tab-history2

            @activityFunctionCalled="activityFunctionCalled"
        />
      </b-tab>

      <!--      <b-tab>-->
      <!--        <template #title>-->
      <!--          <icon icon="stream"/>-->
      <!--          <span>{{ capitalize($t('history')) }}</span>-->
      <!--        </template>-->

      <!--        <activity-tab-history :work-flo="workFloLocal"/>-->
      <!--      </b-tab>-->

      <b-tab>
        <template #title>
          <icon icon="tasks"/>
          <span class="ml-1">{{ capitalize($tc('task', 2)) }}</span>
        </template>

        <activity-tab-tasks
            :work-flo="workFloLocal"
            ref="activityTabTasksRef"

            @updateWorkFlo="$emit('updateWorkFlo')"
        />

      </b-tab>

      <b-tab>
        <template #title>
          <icon icon="comment-dots"/>
          <span class="ml-1">{{ capitalize($tc('note', 2)) }}</span>
        </template>

        <activity-tab-notes
            :work-flo="workFloLocal"
            ref="activityTabNotesRef"

            @updateWorkFlo="$emit('updateWorkFlo')"
        />
      </b-tab>

<!--      <b-tab v-if="getUserData().roles.includes('ROLE_ADMIN')">-->
      <b-tab>
        <template #title>
          <icon icon="envelope"/>
          <span class="ml-1">{{ capitalize($tc('mail', 2)) }}</span>
        </template>

        <activity-tab-emails
            :work-flo="workFloLocal"
            ref="activityTabEmailsRef"
        />
      </b-tab>


      <b-tab>
        <template #title>
          <icon icon="phone"/>
          <span class="ml-1">{{ capitalize($tc('phoneCall', 2)) }}</span>
        </template>

        <activity-tab-calls
            :work-flo="workFloLocal"
            ref="activityTabCallsRef"

            @updateWorkFlo="$emit('updateWorkFlo')"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <icon icon="calendar-alt"/>
          <span class="ml-1">{{ capitalize($tc('event', 2)) }}</span>
        </template>

        <activity-tab-events
            :work-flo="workFloLocal"
            ref="activityTabEventsRef"

            @updateWorkFlo="$emit('updateWorkFlo')"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <icon icon="map-marked-alt"/>
          <span class="ml-1">{{ capitalize($tc('intervention', 2)) }}</span>
        </template>

        <activity-tab-interventions
            :work-flo="workFloLocal"
            ref="activityTabInterventionsRef"

            @updateWorkFlo="$emit('updateWorkFlo')"
        />
      </b-tab>

    </b-tabs>


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '../../../utils/filter'

import ActivityTabHistory       from './ActivityTabHistory.vue'
import ActivityTabTasks         from './ActivityTabTasks.vue'
import ActivityTabNotes         from './ActivityTabNotes.vue'
// import ActivityTabEmails        from './ActivityTabEmails.vue'
import ActivityTabEmails        from './ActivityTab/ActivityTabEmails.vue'
import ActivityTabCalls         from './ActivityTabCalls.vue'
import ActivityTabEvents        from './ActivityTabEvents.vue'
import ActivityTabInterventions from './ActivityTabInterventions.vue'
import ActivityTabCalendar      from './ActivityTabCalendar.vue'
import ActivityTabCalendar2     from './ActivityTabCalendar2.vue'
import activityTabCalendar      from './ActivityTabCalendar.vue'
import ActivityTabHistory2      from './ActivityTabHistory2.vue'
import { getUserData }          from '../../../utils/utils'

export default {
  components: {
    ActivityTabHistory2,
    ActivityTabHistory,
    ActivityTabTasks,
    ActivityTabNotes,
    ActivityTabEmails,
    ActivityTabCalls,
    ActivityTabEvents,
    ActivityTabInterventions,
    ActivityTabCalendar,
    ActivityTabCalendar2,
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const activityTabCalendar2 = ref()
    const activityTabTasksRef = ref()
    const activityTabNotesRef = ref()
    const activityTabCallsRef = ref()
    const activityTabEventsRef = ref()
    const activityTabInterventionsRef = ref()

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const addNewTask = () => {
      activityTabTasksRef.value.openPrompt()
    }

    const addNewNote = () => {
      activityTabNotesRef.value.openNoteForm()
    }

    const addNewCall = () => {
      activityTabCallsRef.value.openPrompt()
    }

    const addNewEvent = () => {
      // activityTabEventsRef.value.openPrompt()
      activityTabCalendar2.value.addEvent()
    }

    const updateEvent = (event) => {
      // activityTabEventsRef.value.editWorkFloEvent(event)
    }

    const addNewIntervention = () => {
      activityTabInterventionsRef.value.openPrompt()
    }

    const activityFunctionCalled = ({ entityType, entity, functionCalled }) => {
      // console.log(entityType, entity, functionCalled)

      if (entityType == 'event') {
        if (functionCalled == 'edit') {
          activityTabCalendar2.value.eventsCalendarRef.eventClick(entity._calendarEvent)
        } else if (functionCalled == 'delete') {
          activityTabCalendar2.value.eventsCalendarRef.removeEventAlert(entity)
        }
      } else if (entityType == 'reminder') {
        if (functionCalled == 'edit') {
          activityTabCalendar2.value.editReminderLocal(entity)
        } else if (functionCalled == 'delete') {
          activityTabCalendar2.value.removeReminderLocal(entity)
        }
      } else if (entityType == 'offer') {
        emit('offerFunctionCalled', { entity: entity, functionCalled: functionCalled })
      } else if (entityType == 'outgoingInvoice') {
        emit('outgoingInvoiceFunctionCalled', { entity: entity, functionCalled: functionCalled })
      } else if (entityType == 'incomingInvoice') {
        emit('incomingInvoiceFunctionCalled', { entity: entity, functionCalled: functionCalled })
      } else if (entityType == 'workFloNote') {
        if (functionCalled == 'edit') {
          activityTabNotesRef.value.editWorkFloNote(entity)
        } else if (functionCalled == 'delete') {
          activityTabNotesRef.value.deleteWorkFloNoteAlert(entity)
        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      activityTabCalendar2,
      activityTabTasksRef,
      activityTabNotesRef,
      activityTabCallsRef,
      activityTabEventsRef,
      activityTabInterventionsRef,

      // Computed
      workFloLocal,

      // Methods
      addNewTask,
      addNewNote,
      addNewCall,
      addNewEvent,
      updateEvent,
      addNewIntervention,
      activityFunctionCalled,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { getUserData },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>