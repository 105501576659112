<template>
    <div>
        <work-flo-event-card
                v-for="(event, index) in workFloLocal.events"
                :key="'workFloEvent-'+index"
                :work-flo-event="event"

                @editClick="editWorkFloEvent(event)"
                @trashClick="removeWorkFloEvent(event)"
        />


        <work-flo-event-prompt
                :is-open.sync="isWorkFloEventPromptOpen"
                :work-flo-event.sync="currentWorkFloEvent"
                :work-flo="workFloLocal"
                :title="('id' in currentWorkFloEvent)?$t('EditWorkFloEvent'):$t('NewWorkFloEvent')"

                @submitValidated="submitValidatedWorkFloEventLocal"
        />
    </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'

import WorkFloEventPrompt from '../../../components/prompt/WorkFloEventPrompt.vue'
import store from '../../../store'
import WorkFloEventCard from '../../../components/card/WorkFloEventCard.vue'

export default {
  components: {
    WorkFloEventCard,
    WorkFloEventPrompt,
  },
  props: {
    workFlo: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isWorkFloEventPromptOpen = ref(false)
    const currentWorkFloEvent = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workFloLocal = computed({
      get () {
        return props.workFlo
      },
      set (val) {
        emit('update:workFlo', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isWorkFloEventPromptOpen, val => {
      if (val === false) {
        resetCurrentWorkFloEvent()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const openPrompt = () => {
      isWorkFloEventPromptOpen.value = true
    }

    const resetCurrentWorkFloEvent = () => {
      currentWorkFloEvent.value = JSON.parse(JSON.stringify(store.getters['workFlo/getEmptyWorkFloEvent']))
      currentWorkFloEvent.value.workFlo = workFloLocal.value.id
    }

    const submitValidatedWorkFloEventLocal = () => {
      if ('id' in currentWorkFloEvent.value) {
        let index = workFloLocal.value.events.findIndex(e => e.id === currentWorkFloEvent.value.id)
        workFloLocal.value.events.splice(index, 1, JSON.parse(JSON.stringify(currentWorkFloEvent.value)))
      } else {
        workFloLocal.value.events.push(JSON.parse(JSON.stringify(currentWorkFloEvent.value)))
      }

      // console.log(workFloLocal.value)

      emit('updateWorkFlo')

      isWorkFloEventPromptOpen.value = false
    }

    const editWorkFloEvent = (event) => {
      currentWorkFloEvent.value = JSON.parse(JSON.stringify(event))
      isWorkFloEventPromptOpen.value = true
    }
    const removeWorkFloEvent = (event) => {
      let index = workFloLocal.value.events.findIndex(i => i.id === event.id)
      workFloLocal.value.events.splice(index, 1)

      emit('updateWorkFlo')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetCurrentWorkFloEvent()

    return {
      // Components
      capitalize,

      // Data
      isWorkFloEventPromptOpen,
      currentWorkFloEvent,

      // Computed
      workFloLocal,

      // Methods
      openPrompt,
      submitValidatedWorkFloEventLocal,
      editWorkFloEvent,
      removeWorkFloEvent,

    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>