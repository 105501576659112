<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="lg"
      scrollable
      :hide-footer="hideFooter"
      :ok-title="capitalize($t('validate'))"
      :cancel-title="capitalize($t('cancel'))"
      @hide="tryCloseWorkFloInterventionModal"
      @hidden="handleCancel"
      @ok="handleOk"
  >
    <validation-observer
        ref="observerRef"
        #default="{handleSubmit, errors}">
      <b-form @submit.prevent="handleSubmit(submitValidatedWorkFloInterventionLocal)">
        <work-flo-intervention-form
            :work-flo-intervention="workFloInterventionLocal"
            :work-flo="workFlo"
            :formErrors="errors"
        />
        <button ref="submitWorkFloInterventionFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { capitalize } from '../../utils/filter'

import useAPI from '../../utils/useAPI'
import WorkFloInterventionForm from '../form/WorkFloInterventionForm.vue'

export default {
  components: {
    ValidationObserver,
    WorkFloInterventionForm
  },
  props: {
    workFloIntervention: {
      type: Object,
      default: () => {}
    },
    workFlo: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const workFloInterventionLocal = ref(props.workFloIntervention)
    const submitWorkFloInterventionFormRef = ref(null)
    const observerRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(workFloInterventionLocal, (val) => {
      emit('update:workFloIntervention', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseWorkFloInterventionModal = (modalIntervention) => {
      if (modalIntervention.trigger == 'backdrop') {
        // Click outside
        modalIntervention.preventDefault()
        submitWorkFloInterventionFormRef.value.click()
      }
    }

    const submitValidatedWorkFloInterventionLocal = () => {
      emit('submitValidated')
    }

    const handleOk = async (bvModalIntervention) => {
      bvModalIntervention.preventDefault()

      const isValid = await observerRef.value.validate()
      if (isValid) {
        submitValidatedWorkFloInterventionLocal()
      }
    }

    const handleCancel = () => {}

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      workFloInterventionLocal,
      submitWorkFloInterventionFormRef,
      observerRef,

      // Computed
      hideFooter,

      // Methods
      tryCloseWorkFloInterventionModal,
      submitValidatedWorkFloInterventionLocal,
      handleOk,
      handleCancel,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    workFloIntervention: function (val) {
      this.workFloInterventionLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>