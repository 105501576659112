<template>
  <div>
    <b-card
        no-body
        class="overflow-hidden p-1"
        @mouseover="isHovered=true"
        @mouseleave="isHovered=false"
        @click="$emit('click')"
    >
      <div class="d-flex">
        <div class="w-100">
          <!--          Line 1-->
          <div class="d-flex justify-content-between">
            <div
                class="d-flex"
                :style="'width: calc(98% - '+iconsWidth+')'"
            >
              <div v-if="isSelectable">
                <b-form-checkbox
                    v-model="check"
                />
              </div>
              <div>
                <icon
                    :icon="icon.icon"
                    class="mx-50"
                />
              </div>
              <!--              <div class="text-truncate" style="max-width: 14vw">-->
              <div class="text-truncate">
                <b>{{ event.title }}</b>
              </div>
              <div
                  v-if="event.startDate != null"
                  class="ml-50"
              >
                <b-badge :variant="daysFromNow.color">
                  {{ daysFromNow.text }}
                </b-badge>
              </div>
            </div>

            <div
                class="my-auto d-flex justify-content-end"
                :style="'width: '+iconsWidth+ ';'"
            >
              <icon
                  v-if="'documents' in event && event.documents.length > 0"
                  icon="paperclip"
                  class="mx-50"
              />
              <icon
                  v-if="event.notes != null"
                  icon="sticky-note"
                  class="mx-50"
              />
              <icon
                  v-if="'steps' in event && event.steps.length > 0"
                  icon="list-ul"
                  class="mx-50"
              />
              <!--              <icon-->
              <!--                  v-if="!isHistory && (event.workFlo != null || event.workflow != null )"-->
              <!--                  icon="th-list"-->
              <!--                  class="mx-50"-->
              <!--              />-->
            </div>

          </div>

          <!--          Line 2-->
          <div class="d-flex mt-1">
            <div
                class="d-flex"
                :style="'max-width: '+maxUsersWidth+';'"
            >
              <b-avatar
                  v-if="event._createdBy != null && event._createdBy.avatar"
                  class="pull-up"
                  :src="event._createdBy.avatar.url"
                  variant="light-primary"
                  size="sm"
              />
              <b-avatar
                  v-else
                  class="pull-up"
                  :text="event._createdBy!=null?initials(event._createdBy._display):'?'"
                  variant="light-primary"
                  size="sm"
              />

              <div class="ml-50 text-truncate">
                {{ event._createdBy != null ? event._createdBy._display : '?' }}
              </div>
            </div>

            <div
                v-if="event.startDate != null && !isHistory"
                ref="eventCardDateRef"
                class="d-flex ml-50 text-muted text-nowrap"
            >
              <icon
                  icon="calendar-day"
                  class="mr-50"
              />
              {{ event.startDate|moment('D MMM') }}
            </div>

            <div
                v-if="isHistory && displayLastUpdate != null"
                class="d-flex text-secondary text-nowrap ml-4"
            >
              <icon icon="history"/>

              <div class="ml-50">
                <small>
                  <i>{{ displayLastUpdate }}</i>
                </small>
              </div>
            </div>

          </div>

          <!--          Line 3-->
          <div
              v-if="event.notes != null"
              class="d-flex mt-1"
          >
            <v-clamp
                autoresize
                :max-lines="3">
              {{ escapeHtml(event.notes) }}
              <template #after="{ toggle, clamped }">
                <span
                    v-if="clamped == true"
                    @click="toggle"
                    class="ml-50 text-primary cursor-pointer">
                  <b><i>{{ capitalize($t('viewAll')) }}</i></b>
                </span>
              </template>
            </v-clamp>
          </div>
        </div>

        <Transition name="slide">
          <div
              v-if="isHovered && (isEditable || isDeletable)"
              class="my-auto d-flex"
          >
            <edit-button
                @click.native.stop="$emit('edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :disabled="!isEditable"
                size="sm"
            />

            <remove-button
                @click.native.stop="$emit('delete')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                :is-disabled="!isDeletable"
                size="sm"
            />
          </div>
        </Transition>
      </div>

    </b-card>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import ContentWorkFlo           from './components/WorkFloContent.vue'
import GenericCard              from './Card.vue'
import moment                   from 'moment'
import i18n                     from '../../libs/i18n'
import { capitalize }           from '../../utils/filter'
import { clone, initials, escapeHtml }      from '../../utils/utils'
import EditButton               from '../button/Edit.vue'
import RemoveButton             from '../button/Remove.vue'
import VClamp from 'vue-clamp'

export default {
  components: { GenericCard, ContentWorkFlo, EditButton, RemoveButton, VClamp },
  props: {
    event: {
      type: Object,
      default: () => {}
    },
    isSelectable: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    },
    isDeletable: {
      type: Boolean,
      default: true
    },
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(clone(props.event))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const check = ref(false)
    const isHovered = ref(false)

    const eventCardDateRef = ref()
    const eventCardPriorityRef = ref()

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const daysFromNow = computed(() => {
      let days = moment(props.event.startDate).diff(moment(), 'days')

      if (props.isHistory) {
        return {
          days: days,
          color: 'secondary',
          text: capitalize(moment(props.event.startDate).fromNow())
        }
      } else {
        return {
          days: days,
          color: days < 0 ? 'danger' : (days == 0 ? 'secondary' : 'success'),
          text: days < 0 ? (capitalize(i18n.tc('xDaysLate', -days, { days: -days }))) : (capitalize(i18n.tc('xDaysRemaining', days, { days: days })))
        }
      }
    })

    const icon = computed(() => {
      if (props.event.type == 'discussion') {
        return {
          icon: 'comments',
          library: 'fas'
        }
      } else if (props.event.type == 'call') {
        return {
          icon: 'phone',
          library: 'fas'
        }
      } else if (props.event.type == 'mail') {
        return {
          icon: 'envelope',
          library: 'fas'
        }
      } else if (props.event.type == 'intervention') {
        return {
          icon: 'briefcase',
          library: 'fas'
        }
      } else if (props.event.type == 'trip') {
        return {
          icon: 'suitcase-rolling',
          library: 'fas'
        }
      } else if (props.event.type == 'absence') {
        return {
          icon: 'glasses',
          library: 'fas'
        }
      } else {
        return {
          icon: 'user-circle',
          library: 'fas'
        }
      }
    })

    const iconsWidth = computed(() => {
      let output = 0
      let iconWidth = 28

      // if (props.event.documents.length > 0) {
      //   output += iconWidth
      // }
      // if (props.event.notes != null) {
      //   output += iconWidth
      // }
      // if (props.event.steps.length > 0) {
      //   output += iconWidth
      // }
      // if (props.event.workFlo != null || props.event.workflow != null) {
      //   output += iconWidth
      // }

      return output + 'px'
    })

    const maxUsersWidth = computed(() => {
      if (eventCardDateRef.value != undefined && eventCardPriorityRef.value != undefined) {
        let width = eventCardDateRef.value.offsetWidth + eventCardPriorityRef.value.offsetWidth
        return 'calc(90% - ' + width + 'px)'
      } else {
        return '100%'
      }

    })

    const lastUpdate = computed(() => {
      if (props.event.logs.length == 0) {
        return null
      } else {
        return props.event.logs.slice(-1)[0]
      }
    })

    const displayLastUpdate = computed(() => {
      if (lastUpdate == null || lastUpdate.value.action == 'create') {
        return null
      } else {
        return capitalize(i18n.t('lastUpdateDate', { 'date': moment(lastUpdate.value.at).fromNow() }))
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(check, val => {
    //   emit('checkReminder')
    // })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      escapeHtml,

      // Data
      check,
      isHovered,
      eventCardDateRef,
      eventCardPriorityRef,

      // Computed
      daysFromNow,
      iconsWidth,
      maxUsersWidth,
      icon,
      displayLastUpdate,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { initials, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>
.badge:empty {
  display: block;
  width: 20px;
  height: 20px;
}
</style>